import axios from 'axios';
import { getLocale } from '@/lib/locale';
import merge from '@/lib/merge';
import SessionData from '@/lib/session-data';
import UserAgent from '@/lib/user-agent';
import { getIdentifier } from './organization-utils';
export function acceptHeader() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var vendor = config.vendor || process.env.API_VENDOR;
  var apiVersion = process.env.API_VERSION;

  if (!vendor || !apiVersion) {
    throw new Error('Api version or vendor not set');
  } else {
    return "application/vnd.".concat(vendor, "+json; version=").concat(apiVersion);
  }
}
var defaultConfigTimeout = 30 * 1000;

var defaultConfig = function defaultConfig() {
  var _Number, _SessionData$language, _SessionData$language2;

  return {
    timeout: (_Number = Number(process.env.REQUEST_TIMEOUT)) !== null && _Number !== void 0 ? _Number : defaultConfigTimeout,
    headers: {
      Accept: acceptHeader(),
      'Accept-Language': (_SessionData$language = SessionData.language()) !== null && _SessionData$language !== void 0 ? _SessionData$language : getLocale(),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Expires: '0',
      'If-Modified-Since': '0',
      Pragma: 'no-cache',
      'X-App-Install-Identifier': 'motimate-web',
      'X-App-Type': 'web',
      'X-App-Version': UserAgent.getRelease(),
      'X-Client-Info': 'motimate-web',
      'X-User-Language': (_SessionData$language2 = SessionData.language()) !== null && _SessionData$language2 !== void 0 ? _SessionData$language2 : getLocale(),
      'X-Legacy-Auth0-Flow': UserAgent.isLegacyAuth0Flow() ? '1' : '0'
    }
  };
};

export function baseAPIURL() {
  var host = "https://".concat(getIdentifier(), ".motimate.app");
  var protocol = process.env.API_PROTOCOL;

  if (!host || !protocol) {
    throw new Error('Host or protocol not set');
  } else {
    return "".concat(protocol, "://").concat(host, "/api");
  }
}
export function baseURLForOrganization() {
  var organization = SessionData.organizationIdentifier();
  var host = "https://".concat(getIdentifier(), ".motimate.app");
  var protocol = process.env.API_PROTOCOL;

  if (!host || !protocol) {
    throw new Error('Host or protocol not set');
  } else {
    return "".concat(protocol, "://").concat(organization, ".").concat(host);
  }
}
export function baseAPIURLForOrganization() {
  return "".concat(baseURLForOrganization(), "/api");
}
export function create() {
  var overrideConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var config = merge(defaultConfig(), overrideConfig);
  var client = axios.create(config);
  return client;
}
export function nonScopedClient() {
  var overrideConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var config = merge({
    baseURL: baseAPIURL()
  }, overrideConfig);
  return create(config);
}
export function authClient() {
  var overrideConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var authDefaults = merge(defaultConfig(), {
    baseURL: baseURLForOrganization()
  });
  var config = merge(authDefaults, overrideConfig);
  return create(config);
}
export function withToken() {
  var overrideConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var config = merge({
    baseURL: baseAPIURLForOrganization()
  }, overrideConfig);
  return create(config);
}
export default create();