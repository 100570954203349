import * as actionsMobile from '@/redux/actions/mobile';
export var initialState = null;
export var reducerMobile = function reducerMobile() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionsMobile.MOBILE_INIT:
      return {
        urn: action.payload.urn
      };

    default:
      return state;
  }
};
export default reducerMobile;