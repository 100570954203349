import _mapValues from "lodash/mapValues";
import _isPlainObject from "lodash/isPlainObject";
import camelCase from 'camelcase';
import mapKeys from 'lodash.mapkeys';
import snakeCase from 'lodash.snakecase';
import { pipe, values } from 'ramda';
import { sortByIndex } from '@/lib/array-utils';

function mapKeysDeep(obj, cb) {
  var isRecursive = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

  if (!obj && !isRecursive) {
    return {};
  }

  if (!isRecursive) {
    if (typeof obj === 'string' || typeof obj === 'number' || typeof obj === 'boolean') {
      return {};
    }
  }

  if (Array.isArray(obj)) {
    return obj.map(function (item) {
      return mapKeysDeep(item, cb, true);
    });
  }

  if (!_isPlainObject(obj)) {
    return obj;
  }

  var result = mapKeys(obj, cb);
  return _mapValues(result, function (value) {
    return mapKeysDeep(value, cb, true);
  });
}

export var mapKeysToCamelCase = function mapKeysToCamelCase(object) {
  return mapKeys(object, function (value, key) {
    return camelCase(key);
  });
};
export var mapKeysDeepToCamelCase = function mapKeysDeepToCamelCase(object) {
  return mapKeysDeep(object, function (value, key) {
    return camelCase(key);
  });
};
export var mapKeysToSnakeCase = function mapKeysToSnakeCase(object) {
  return mapKeys(object, function (value, key) {
    return snakeCase(key);
  });
};
export var mapKeysDeepToSnakeCase = function mapKeysDeepToSnakeCase(object) {
  return mapKeysDeep(object, function (value, key) {
    return snakeCase(key);
  });
};
export var sortValuesByIndex = pipe(values, sortByIndex);