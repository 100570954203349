import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { isProduction } from '@/lib/env-utils';
import reducers from '@/redux/reducers';

var storeCreator = function storeCreator(initialState) {
  var enhancers = [];
  return createStore(reducers(), initialState, isProduction() ? compose.apply(void 0, [applyMiddleware(thunk)].concat(enhancers)) : composeWithDevTools.apply(void 0, [applyMiddleware(thunk)].concat(enhancers)));
};

export default storeCreator;