export var PLAYER_ENDED = 'PLAYER_ENDED';
export var PLAYER_PAUSE = 'PLAYER_PAUSE';
export var PLAYER_PLAY = 'PLAYER_PLAY';
export var ended = function ended(id) {
  return {
    type: PLAYER_ENDED,
    payload: {
      id: id
    }
  };
};
export var pause = function pause(id) {
  return {
    type: PLAYER_PAUSE,
    payload: {
      id: id
    }
  };
};
export var play = function play(id) {
  return {
    type: PLAYER_PLAY,
    payload: {
      id: id
    }
  };
};