import svgToMiniDataURI from 'mini-svg-data-uri';
import { css } from 'styled-components';
import { theme } from 'styled-tools';
var _mobile = 799;

var _tablet = _mobile + 1;

var _profilebar = 1080;
var _desktopSmall = 1000;
var _desktop = 1200;
var _desktopWide = 1420;
export var isMobileScreen = function isMobileScreen() {
  return window.innerWidth <= _mobile;
};
export var isTabletScreen = function isTabletScreen() {
  return window.innerWidth <= _desktopSmall;
};
export var textEllipsis = function textEllipsis() {
  return css(["white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"]);
};
export var clearfix = function clearfix() {
  return css(["&::after{content:'';clear:both;display:table;}"]);
}; // Browser support 95%

export var lineClamp = function lineClamp() {
  var lines = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 2;
  return css(["display:-webkit-box;line-clamp:", ";-webkit-line-clamp:", ";-webkit-box-orient:vertical;overflow:hidden;"], lines, lines);
};
export var media = {
  mobile: function mobile(first) {
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    return css(["@media only screen and (max-width:", "px){", "}"], _mobile, css.apply(void 0, [first].concat(args)));
  },
  tablet: function tablet(first) {
    for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      args[_key2 - 1] = arguments[_key2];
    }

    return css(["@media only screen and (min-width:", "px){", "}"], _tablet, css.apply(void 0, [first].concat(args)));
  },
  desktopSmall: function desktopSmall(first) {
    for (var _len3 = arguments.length, args = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
      args[_key3 - 1] = arguments[_key3];
    }

    return css(["@media only screen and (min-width:", "px){", "}"], _desktopSmall, css.apply(void 0, [first].concat(args)));
  },
  profilebar: function profilebar(first) {
    for (var _len4 = arguments.length, args = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
      args[_key4 - 1] = arguments[_key4];
    }

    return css(["@media only screen and (min-width:", "px){", "}"], _profilebar, css.apply(void 0, [first].concat(args)));
  },
  desktop: function desktop(first) {
    for (var _len5 = arguments.length, args = new Array(_len5 > 1 ? _len5 - 1 : 0), _key5 = 1; _key5 < _len5; _key5++) {
      args[_key5 - 1] = arguments[_key5];
    }

    return css(["@media only screen and (min-width:", "px){", "}"], _desktop, css.apply(void 0, [first].concat(args)));
  },
  desktopWide: function desktopWide(first) {
    for (var _len6 = arguments.length, args = new Array(_len6 > 1 ? _len6 - 1 : 0), _key6 = 1; _key6 < _len6; _key6++) {
      args[_key6 - 1] = arguments[_key6];
    }

    return css(["@media only screen and (min-width:", "px){", "}"], _desktopWide, css.apply(void 0, [first].concat(args)));
  }
};
export var ifPropPresent = function ifPropPresent(propName) {
  return function (positive, negative) {
    return function (props) {
      return propName in props ? positive : negative;
    };
  };
};
export function ifProp(propName) {
  return function (propValue, styles) {
    return function (props) {
      return props[propName] === propValue && styles;
    };
  };
}
export var ifSizeEq = ifProp('size');
export var ifVariantEq = ifProp('variant');
export var spacingHor = function spacingHor(margin) {
  var unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'px';
  return "\n  > * {\n    margin-right: ".concat(margin).concat(unit, ";\n\n    &:last-child {\n      margin-right: 0;\n    }\n  }\n");
};
export var spacingVer = function spacingVer(margin) {
  var unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'px';
  return "\n  > * {\n    margin-bottom: ".concat(margin).concat(unit, ";\n\n    &:last-child {\n      margin-bottom: 0;\n    }\n  }\n");
};
export var themeColorPrimary = function themeColorPrimary(props) {
  return props.themeColorPrimary || theme('color.primary')(props);
};
export var inView = css(["margin-top:", ";margin-right:-", ";margin-bottom:", ";margin-left:-", ";&:first-child{margin-top:-", ";border-top-right-radius:", ";border-top-left-radius:", ";}&:last-child{margin-bottom:-", ";border-bottom-right-radius:", ";border-bottom-left-radius:", ";}"], theme('default.padding'), theme('default.padding'), theme('default.padding'), theme('default.padding'), theme('default.padding'), theme('default.border.radius'), theme('default.border.radius'), theme('default.padding'), theme('default.border.radius'), theme('default.border.radius'));
export var imageRenderingCrispEdges = css(["image-rendering:crisp-edges;image-rendering:-webkit-optimize-contrast;"]);
export var breakWord = function breakWord() {
  return css(["word-break:break-word;overflow-wrap:anywhere;"]);
};
export var borderDashed = function borderDashed() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$borderColor = _ref.borderColor,
      borderColor = _ref$borderColor === void 0 ? '#1477ff' : _ref$borderColor,
      _ref$borderRadius = _ref.borderRadius,
      borderRadius = _ref$borderRadius === void 0 ? 10 : _ref$borderRadius;

  var svg = "<svg width=\"100%\" height=\"100%\" xmlns=\"http://www.w3.org/2000/svg\"><rect width=\"100%\" height=\"100%\" fill=\"none\" rx=\"".concat(borderRadius, "\" ry=\"").concat(borderRadius, "\" stroke=\"").concat(borderColor, "ff\" stroke-width=\"3\" stroke-dasharray=\"5, 5\" stroke-dashoffset=\"0\" stroke-linecap=\"butt\" /></svg>");
  return css(["background-image:url(\"", "\");"], svgToMiniDataURI(svg));
};