import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as actionsPlayer from '@/redux/actions/player';
export var initialState = {};

var play = function play(state, action) {
  var nextState = _objectSpread({}, state);

  Object.keys(nextState).forEach(function (id) {
    nextState[id] = nextState[id] === 'PLAYING' ? 'PAUSED' : nextState[id];
  });
  return _objectSpread(_objectSpread({}, nextState), {}, _defineProperty({}, action.payload.id, 'PLAYING'));
};

export var reducerPlayer = function reducerPlayer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionsPlayer.PLAYER_ENDED:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.payload.id, 'ENDED'));

    case actionsPlayer.PLAYER_PAUSE:
      return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.payload.id, 'PAUSED'));

    case actionsPlayer.PLAYER_PLAY:
      return play(state, action);

    default:
      return state;
  }
};
export default reducerPlayer;