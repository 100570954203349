import { getIdentifier } from './organization-utils';
var serverStorage = {
  getItem: function getItem(key) {
    return key;
  },
  removeItem: function removeItem() {
    return null;
  },
  setItem: function setItem() {
    return null;
  },
  clear: function clear() {
    return null;
  },
  key: function key() {
    return null;
  },
  length: 0
};
var storage = true ? localStorage : serverStorage;
var SessionData = {
  organizationIdentifier: function organizationIdentifier() {
    var organization = getIdentifier();

    if (!organization) {
      throw new Error('Organization must be set!');
    }

    return organization;
  },
  removeOrganizationIdentifier: function removeOrganizationIdentifier() {
    storage.removeItem('organization');
  },
  setLanguage: function setLanguage(language) {
    storage.setItem('X-User-Language', language);
  },
  language: function language() {
    var language = storage.getItem('X-User-Language');
    var typedLanguage = language;
    return typedLanguage;
  },
  accessToken: function accessToken() {
    return storage.getItem('accessToken');
  },
  clean: function clean() {
    storage.removeItem('accessToken');
    storage.removeItem('clientId');
    storage.removeItem('expiresOn');
    storage.removeItem('mechanism');
    storage.removeItem('refreshToken');
    storage.removeItem('refreshTokenUrl');
    storage.removeItem('resource');
    storage.removeItem('tokenResource');
    storage.removeItem('X-User-Language');
  }
};
export default SessionData;