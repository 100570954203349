import styled, { css } from 'styled-components';
import { prop, switchProp, theme } from 'styled-tools';
import { darken } from '@/lib/color-utils';
var UILink = styled.a.withConfig({
  displayName: "UILink",
  componentId: "nz9f86-0"
})(["text-decoration:none;&:disabled{opacity:0.65;}&:focus,&:hover{text-decoration:underline;}&:not(:disabled){cursor:pointer;}", ""], switchProp(prop('variant', 'default'), {
  "default": css(["color:", ";&:focus,&:hover{color:", ";}"], theme('link.color'), function (props) {
    return darken(theme('link.color')(props));
  }),
  disabled: css(["color:", ";&:focus,&:hover{color:", ";}"], theme('link.color.disabled'), function (props) {
    return darken(theme('link.color.disabled')(props));
  }),
  primary: css(["color:", ";&:focus,&:hover{color:", ";}"], theme('color.primary'), function (props) {
    return darken(theme('color.primary')(props));
  })
}));
export default UILink;