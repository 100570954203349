import Color from 'color';
export var colors = ['#3366CC', '#A05AF2', '#975AF3', '#CE77FF', '#FF4EC0', '#E00053', '#FF404F', '#FF812D', '#FDD700', '#C4E300', '#8DBC00', '#009999', '#0099CC', '#000000'];
export var darken = function darken(value) {
  var ratio = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.15;
  return Color(value).darken(ratio).hex().toString();
};
export var isHex = function isHex(value) {
  return Boolean(value && /^#([0-9A-F]{6})$/i.test(value));
};
export var rgba = function rgba(color) {
  var alpha = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return Color(color).alpha(alpha).string();
};