export var APP_UPDATE_ERROR = 'APP_UPDATE_ERROR';
export var APP_UPDATE_START = 'APP_UPDATE_START';
export var APP_UPDATE_SUCCESS = 'APP_UPDATE_SUCCESS';
export var updateError = function updateError() {
  return {
    type: APP_UPDATE_ERROR
  };
};
export var updateStart = function updateStart() {
  return {
    type: APP_UPDATE_START
  };
};
export var updateSuccess = function updateSuccess() {
  window.swUpdate = true;
  return {
    type: APP_UPDATE_SUCCESS
  };
};