import dotProp from 'dot-prop-immutable';
import { values } from 'ramda';
import * as actionsTrainingFavorites from '@/redux/actions/training/favorites';
import * as actionsTrainingCategories from '@/redux/actions/user-categories';
export var initialState = {
  objects: {}
};

var updateCourseFavorite = function updateCourseFavorite(state, action) {
  var _action$payload = action.payload,
      slug = _action$payload.slug,
      favorite = _action$payload.favorite;
  var categoryId = '$unknown';
  var courseId = '$unknown';
  values(state.objects).forEach(function (category) {
    if (category) {
      values(category.courses).forEach(function (course) {
        if (course.slug === slug) {
          courseId = course.id;
          categoryId = category.id;
        }
      });
    }
  });
  var path = "objects.".concat(categoryId, ".courses.").concat(courseId, ".favorite");

  if (courseId === '$unknown') {
    return state;
  }

  return dotProp.set(state, path, favorite);
};

var reducerTrainingCategories = function reducerTrainingCategories() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionsTrainingCategories.USER_CATEGORIES_FETCH_SUCCESS:
    case actionsTrainingCategories.USER_CATEGORIES_COURSES_FETCH_SUCCESS:
      return dotProp.set(state, "objects.".concat(action.payload.id), action.payload);

    case actionsTrainingFavorites.COURSE_FAVOURITE_UPDATE_START:
    case actionsTrainingFavorites.COURSE_FAVOURITE_UPDATE_SUCCESS:
    case actionsTrainingFavorites.COURSE_FAVOURITE_UPDATE_ERROR:
      return updateCourseFavorite(state, action);

    default:
      return state;
  }
};

export default reducerTrainingCategories;