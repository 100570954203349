export var THEME_CHANGE_MODE = 'THEME_CHANGE_MODE';
export var THEME_INIT = 'THEME_INIT';
export var changeThemeMode = function changeThemeMode(mode) {
  return {
    type: THEME_CHANGE_MODE,
    payload: {
      mode: mode
    }
  };
};
export var initTheme = function initTheme(mode, colors) {
  return {
    type: THEME_INIT,
    payload: {
      mode: mode,
      colors: colors
    }
  };
};