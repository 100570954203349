import dotProp from 'dot-prop-immutable';
import { toObject } from '@/lib/array-utils';
import * as actions from '@/redux/actions/training/favorites';
export var initialState = {
  objects: {
    courses: null,
    learningPaths: null
  }
};

var reducerTrainingFavorites = function reducerTrainingFavorites() {
  var _state$objects$course;

  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actions.TRAINING_FAVORITES_RESET:
      return dotProp.set(state, 'objects', {
        courses: null,
        learningPaths: null
      });

    case actions.TRAINING_FAVORITES_FETCH_SUCCESS:
      return dotProp.set(state, 'objects', {
        courses: toObject(action.payload.courses, 'slug'),
        learningPaths: toObject(action.payload.learningPaths || [], 'slug')
      });

    case actions.COURSE_FAVOURITE_UPDATE_START:
    case actions.COURSE_FAVOURITE_UPDATE_SUCCESS:
    case actions.COURSE_FAVOURITE_UPDATE_ERROR:
      if (action.payload.slug in ((_state$objects$course = state.objects.courses) !== null && _state$objects$course !== void 0 ? _state$objects$course : {})) {
        return dotProp.set(state, "objects.courses.".concat(action.payload.slug, ".favorite"), action.payload.favorite);
      }

      return state;

    default:
      return state;
  }
};

export default reducerTrainingFavorites;