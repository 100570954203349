import _objectWithoutProperties from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
var _excluded = ["themeColorPrimary"];
import styled, { css } from 'styled-components';
import { ifProp, prop, switchProp, theme } from 'styled-tools';
import { darken, rgba } from '@/lib/color-utils';
import UIIcon from '@/components/ui/icon';
import { borderDashed, spacingHor } from '@/components/utils/style';
export var buttonVariant = function buttonVariant(backgroundColor, color) {
  var hoverBackgroundColor = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : backgroundColor;
  var hoverColor = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : color;
  return css(["border-color:", ";background-color:", ";color:", ";&:active:not([disabled]),&:focus:not([disabled]){box-shadow:0 0 0 0.3rem ", ";}&:hover:not([disabled]){border-color:", ";background-color:", ";", "}"], backgroundColor, backgroundColor, color, rgba(backgroundColor, 0.4), darken(hoverBackgroundColor), darken(hoverBackgroundColor), color === hoverColor ? '' : css(["color:", ";"], hoverColor));
};
var UIButton = styled.button.withConfig({
  displayName: "UIButton",
  componentId: "sc-9dhd0p-0"
})(["display:inline-flex;flex-shrink:0;text-align:center;text-decoration:none;text-transform:", ";white-space:nowrap;user-select:none;vertical-align:middle;justify-content:center;align-items:center;border-style:solid;border-width:1px;font-weight:700;transition:background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,color .15s ease-in-out,width .15s ease-in-out,top .15s ease-in-out;-webkit-tap-highlight-color:transparent;&:not([disabled]){cursor:pointer;}&[disabled]{opacity:.65;}", " ", " ", " ", " ", " ", " &.UIButton--active{", "}", "{user-select:none;pointer-events:none;transition:fill .15s ease-in-out;}"], prop('textTransform', 'none'), spacingHor(0.5, 'em'), ifProp('boxShadow', css(["box-shadow:0px 3px 7px 1px rgba(0,0,0,0.2);"])), switchProp(prop('size', 'medium'), {
  small: css(["padding:0 0.9rem;height:2.1rem;line-height:1.7rem;font-size:", ";", ""], theme('default.font.size.small'), switchProp(prop('shape', 'pill'), {
    circle: css(["width:2.1rem;"]),
    pill: css(["border-radius:1.2rem;"]),
    rounded: css(["border-radius:0.5rem;"])
  })),
  semiMedium: css(["padding:0 1rem;height:2.6rem;line-height:1.4rem;font-size:1.2rem;", ""], switchProp(prop('shape', 'pill'), {
    circle: css(["width:2.6rem;"]),
    pill: css(["border-radius:1.3rem;"]),
    rounded: css(["border-radius:0.5rem;"])
  })),
  medium: css(["padding:0 1rem;height:3.4rem;line-height:2rem;font-size:", ";", ""], theme('default.font.size'), switchProp(prop('shape', 'pill'), {
    circle: css(["width:3.4rem;"]),
    pill: css(["border-radius:1.7rem;"]),
    rounded: css(["border-radius:0.7rem;"])
  })),
  semiLarge: css(["padding:0 1.25rem;height:4rem;line-height:2rem;font-size:1.5rem;", ""], switchProp(prop('shape', 'pill'), {
    circle: css(["width:4rem;"]),
    pill: css(["border-radius:2rem;"]),
    rounded: css(["border-radius:0.8rem;"])
  })),
  large: css(["padding:0 1.5rem;height:4.4rem;line-height:2.2rem;font-size:1.6rem;", ""], switchProp(prop('shape', 'pill'), {
    circle: css(["width:4.4rem;"]),
    pill: css(["border-radius:2.2rem;"]),
    rounded: css(["border-radius:1rem;"])
  })),
  semiXlarge: css(["padding:0 2.4rem;height:4.8rem;line-height:2.8rem;font-size:2rem;", ""], switchProp(prop('shape', 'pill'), {
    circle: css(["width:4.8rem;"]),
    pill: css(["border-radius:2.4rem;"]),
    rounded: css(["border-radius:1.5rem;"])
  }))
}), switchProp(prop('shape', 'pill'), {
  circle: css(["border-radius:50%;padding:0;"])
}), switchProp(prop('borderRadius'), {
  semiMedium: css(["border-radius:0.5rem;"]),
  large: css(["border-radius:1rem;"])
}), switchProp(prop('variant', 'default'), {
  dashed: css(["border:0;border-radius:0.5rem;font-size:", ";font-weight:600;background-color:", ";color:", ";justify-content:space-between;", ""], theme('default.font.size'), theme('color.white'), theme('color.darkBlue'), borderDashed({
    borderColor: '#d2d5d9',
    borderRadius: 5
  })),
  "default": css(["font-weight:600;", ""], function (props) {
    return buttonVariant(theme('button.background.color')(props), theme('button.color')(props));
  }),
  jumbo: css(["", ""], function (props) {
    return buttonVariant(theme('color.jumbo')(props), theme('color.white')(props));
  }),
  primary: css(["", ""], function (_ref) {
    var themeColorPrimary = _ref.themeColorPrimary,
        props = _objectWithoutProperties(_ref, _excluded);

    return buttonVariant(themeColorPrimary || theme('button.background.color.primary')(props), theme('button.color.primary')(props));
  }),
  secondary: css(["", ""], function (props) {
    return buttonVariant(theme('button.background.color.secondary')(props), theme('button.color.secondary')(props));
  }),
  success: css(["", ""], function (props) {
    return buttonVariant(theme('button.background.color.success')(props), theme('button.color.success')(props));
  }),
  danger: css(["", ""], function (props) {
    return buttonVariant(theme('button.background.color.danger')(props), theme('button.color.danger')(props));
  }),
  white: css(["", ""], function (props) {
    return buttonVariant(theme('button.background.color.white')(props), theme('button.color.white')(props));
  }),
  'white-darkBlue': css(["", ""], function (props) {
    return buttonVariant(theme('button.background.color.white')(props), theme('button.color.white')(props), theme('color.darkBlue')(props), theme('button.color.primary')(props));
  }),
  geyser: css(["", ""], function (props) {
    return buttonVariant(theme('button.background.geyser')(props), theme('color.white')(props));
  }),
  pink: css(["", ""], function (props) {
    return buttonVariant(theme('button.background.color.pink')(props), theme('button.color.pink')(props));
  }),
  portGore: css(["", ""], function (props) {
    return buttonVariant(theme('color.portGore')(props), theme('color.white')(props));
  }),
  darkBlue: css(["", ""], function (props) {
    return buttonVariant(theme('color.darkBlue')(props), theme('button.color.primary')(props));
  }),
  dodgerBlue: css(["", ""], function (props) {
    return buttonVariant(theme('color.dodgerBlue')(props), theme('button.color.primary')(props));
  }),
  nevada: css(["", ""], function (props) {
    return buttonVariant(theme('color.nevada')(props), theme('button.color.primary')(props));
  }),
  approved: css(["", ""], function (props) {
    return buttonVariant(theme('color.approved')(props), theme('color.white')(props));
  }),
  radicalRed: css(["", ""], function (props) {
    return buttonVariant(theme('color.radicalRed')(props), theme('color.white')(props));
  }),
  raven: css(["", ""], function (props) {
    return buttonVariant(theme('color.raven')(props), theme('color.white')(props));
  }),
  lightGray: css(["", ""], function (props) {
    return buttonVariant(theme('color.lightGray')(props), theme('color.lightBlack')(props));
  }),
  lightGrayWarning: css(["", ""], function (props) {
    return buttonVariant(theme('color.lightGray')(props), theme('color.danger')(props));
  }),
  zircon: css(["", " font-weight:400;"], function (props) {
    return buttonVariant(theme('color.zircon')(props), theme('color.shark')(props));
  }),
  transparent: css(["border-color:transparent;background:transparent;:hover{background:transparent;}"])
}), function (props) {
  return buttonVariant(theme('button.background.color.primary')(props), theme('button.color.primary')(props));
}, UIIcon);
export default UIButton;