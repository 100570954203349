import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _slicedToArray from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/slicedToArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

export var initialState = {
  ADMIN_COURSE_FETCH: false,
  ADMIN_COURSES_EDITORS_FETCH: false,
  AUTH_LOGIN: false,
  CHAPTER_COMPLETE: false,
  COURSE_FETCH: false,
  DELEGATED_COURSES_FETCH: false,
  EDITOR_COURSES_FETCH: false,
  EDITOR_COURSE_BADGES_FETCH: false,
  EDITOR_COURSE_COLORS_FETCH: false,
  EDITOR_COURSE_CREATE: false,
  EDITOR_COURSE_CREATE_DRAFT: false,
  EDITOR_COURSE_ICONS_FETCH: false,
  EDITOR_COURSE_PUBLISH: false,
  EDITOR_COURSE_UPDATE: false,
  EDITOR_COURSE_PACKAGE_CREATE: false,
  EDITOR_COURSE_PACKAGE_PUBLISH: false,
  EDITOR_COURSE_PACKAGE_UPDATE: false,
  EDITOR_COURSE_PACKAGES_FETCH: false,
  EDITOR_COURSE_PROMOTE_DRAFT: false,
  EDITOR_COURSE_SETTINGS_CATEGORIES_DELETE: false,
  EDITOR_COURSE_SETTINGS_CATEGORIES_CREATE: false,
  FETCH_GROUP_DATA: false,
  FETCH_REPORT_GROUP_COURSES: false,
  FETCH_REPORT_GROUPS: false,
  FETCH_REPORT_USER: false,
  FETCH_SCOREBOARD_GROUPS: false,
  ORGANIZATION_FETCH: false,
  PROFILE_FETCH: false,
  REPORT_ACTIVITIES_FETCH: false,
  REPORT_GROUPS_FETCH: false,
  REPORT_USERS_FETCH: false,
  REPORT_COLLECTIONS_FETCH: false,
  REPORT_COURSES_FETCH: false,
  USER_FETCH: false,
  USER_ONGOING_FETCH: false,
  TRAINING_SEARCH_FETCH: false,
  TRAINING_SECTIONS_FETCH: false,
  TRAINING_FAVORITES_FETCH: false,
  EDITOR_ASSIGNMENTS_GROUPS_FETCH: false,
  COURSE_FAVOURITE_UPDATE: false
};

var reducerCoreLoading = function reducerCoreLoading() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type,
      payload = action.payload;
  var matches = /(.*)_(ERROR|START|SUCCESS)/.exec(type);

  if (!matches) {
    return state;
  }

  var _matches = _slicedToArray(matches, 3),
      requestName = _matches[1],
      requestState = _matches[2];

  var isStart = requestState === 'START';
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, requestName, isStart && payload ? payload : isStart));
};

export default reducerCoreLoading;