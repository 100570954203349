import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { last, prepend } from 'ramda';
import { toObject } from '@/lib/array-utils';
import ResourceUserCategories from '@/lib/resources/user-categories';
import { catchError } from '@/redux/actions/error';
var ROOT_PATH = {
  id: 'titleDiscover',
  link: '/training/discover'
};
export var USER_CATEGORIES_FETCH_ERROR = 'USER_CATEGORIES_FETCH_ERROR';
export var USER_CATEGORIES_FETCH_START = 'USER_CATEGORIES_FETCH_START';
export var USER_CATEGORIES_FETCH_SUCCESS = 'USER_CATEGORIES_FETCH_SUCCESS';
export var USER_CATEGORIES_COURSES_FETCH_ERROR = 'USER_CATEGORIES_COURSES_FETCH_ERROR';
export var USER_CATEGORIES_COURSES_FETCH_START = 'USER_CATEGORIES_COURSES_FETCH_START';
export var USER_CATEGORIES_COURSES_FETCH_SUCCESS = 'USER_CATEGORIES_COURSES_FETCH_SUCCESS';

var fetchUserCategoriesError = function fetchUserCategoriesError() {
  return {
    type: USER_CATEGORIES_FETCH_ERROR
  };
};

var fetchUserCategoriesStart = function fetchUserCategoriesStart() {
  return {
    type: USER_CATEGORIES_FETCH_START
  };
};

var fetchUserCategoriesSuccess = function fetchUserCategoriesSuccess(payload) {
  return {
    type: USER_CATEGORIES_FETCH_SUCCESS,
    payload: payload
  };
};

export function fetchUserCategories() {
  return function (dispatch) {
    dispatch(fetchUserCategoriesStart());
    return ResourceUserCategories.get().then(function (_ref) {
      var data = _ref.data;
      var id = '$root';
      var title = '$root';
      var uuid = 'ffffffff-ffff-ffff-ffff-ffffffffffff';
      var categories = toObject(data.categories);
      var courses = {};
      var path = [ROOT_PATH];
      dispatch(fetchUserCategoriesSuccess({
        id: id,
        title: title,
        uuid: uuid,
        categories: categories,
        courses: courses,
        path: path
      }));
    })["catch"](function (response) {
      dispatch(fetchUserCategoriesError());
      dispatch(catchError(response));
    });
  };
}

var fetchUserCategoriesCoursesError = function fetchUserCategoriesCoursesError() {
  return {
    type: USER_CATEGORIES_COURSES_FETCH_ERROR
  };
};

var fetchUserCategoriesCoursesStart = function fetchUserCategoriesCoursesStart() {
  return {
    type: USER_CATEGORIES_COURSES_FETCH_START
  };
};

var fetchUserCategoriesCoursesSuccess = function fetchUserCategoriesCoursesSuccess(payload) {
  return {
    type: USER_CATEGORIES_COURSES_FETCH_SUCCESS,
    payload: payload
  };
};

export function fetchUserCategoriesCourses(categoryId) {
  return function (dispatch) {
    dispatch(fetchUserCategoriesCoursesStart());
    return ResourceUserCategories.courses(categoryId).then(function (_ref2) {
      var data = _ref2.data;
      var category = last(data.path);
      var categories = toObject(data.categories);
      var courses = toObject(data.courses);
      var dataPath = data.path.map(function (_ref3) {
        var id = _ref3.id,
            title = _ref3.title;
        return {
          link: "/training/discover/category/".concat(id),
          title: title
        };
      });
      var path = prepend(ROOT_PATH, dataPath);
      dispatch(fetchUserCategoriesCoursesSuccess(_objectSpread(_objectSpread({}, category), {}, {
        categories: categories,
        courses: courses,
        path: path
      })));
    })["catch"](function (response) {
      dispatch(fetchUserCategoriesCoursesError());
      dispatch(catchError(response));
    });
  };
}