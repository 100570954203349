import httpStatus from 'http-status';
import EventApiRender from '@/lib/event-api/render';
import CourseResource from '@/lib/resources/user-course';
import UserAgent from '@/lib/user-agent';
import { catchError } from '@/redux/actions/error';
export var COURSE_FETCH_ERROR = 'COURSE_FETCH_ERROR';
export var COURSE_FETCH_START = 'COURSE_FETCH_START';
export var COURSE_FETCH_SUCCESS = 'COURSE_FETCH_SUCCESS';
export var EDITOR_COURSE_FETCH_ERROR = 'EDITOR_COURSE_FETCH_ERROR';
export var EDITOR_COURSE_FETCH_START = 'EDITOR_COURSE_FETCH_START';
export var EDITOR_COURSE_FETCH_SUCCESS = 'EDITOR_COURSE_FETCH_SUCCESS';
export var COURSES_FETCH_FOR_CATEGORY_ERROR = 'COURSES_FETCH_FOR_CATEGORY_ERROR';
export var COURSES_FETCH_FOR_CATEGORY_START = 'COURSES_FETCH_FOR_CATEGORY_START';
export var COURSES_FETCH_FOR_CATEGORY_SUCCESS = 'COURSES_FETCH_FOR_CATEGORY_SUCCESS';
export var DELEGATED_COURSES_FETCH_ERROR = 'DELEGATED_COURSES_FETCH_ERROR';
export var DELEGATED_COURSES_FETCH_START = 'DELEGATED_COURSES_FETCH_START';
export var DELEGATED_COURSES_FETCH_SUCCESS = 'DELEGATED_COURSES_FETCH_SUCCESS';
export var UPDATE_ACTIVE_COURSE_WITH_CHAPTER = 'UPDATE_ACTIVE_COURSE_WITH_CHAPTER';
export var DELETE_COURSE_FROM_REDUX_STORE = 'DELETE_COURSE_FROM_REDUX_STORE';
export var deleteCourseFromReduxStore = function deleteCourseFromReduxStore(id) {
  return {
    type: DELETE_COURSE_FROM_REDUX_STORE,
    payload: {
      id: id
    }
  };
};
export var fetchCourseStart = function fetchCourseStart() {
  return {
    type: COURSE_FETCH_START
  };
};
export var fetchCourseError = function fetchCourseError() {
  return {
    type: COURSE_FETCH_ERROR
  };
};
export var fetchCourseSuccess = function fetchCourseSuccess(course) {
  return {
    type: COURSE_FETCH_SUCCESS,
    course: course
  };
};
export var fetchCourse = function fetchCourse(slug) {
  var onFinished = function onFinished() {
    if (UserAgent.isMobileApp()) {
      EventApiRender.completed();
    }
  };

  return function (dispatch) {
    dispatch(fetchCourseStart());
    return CourseResource.find(slug).then(function (response) {
      dispatch(fetchCourseSuccess(response.data.course));
      if (onFinished) onFinished();
    }, function (error) {
      var responseStatus = error.response ? error.response.status : 401;
      var propagateError = responseStatus !== httpStatus.NOT_FOUND;
      dispatch(fetchCourseError());
      dispatch(catchError(error, {}, {
        propagateError: propagateError
      }));

      if (onFinished) {
        onFinished();
      }

      throw error;
    });
  };
};