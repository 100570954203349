export default {
  features: {
    phoneNumber: true,
    scoreboard: false
  },
  permissions: {
    canAccessAdmin: false,
    canCreateCourse: false,
    documents: false,
    editor: false,
    ratings: false,
    reports: false
  },
  debounce: {
    search: 1000
  },
  video: {
    transformations: 'q_auto,vc_h264,ac_aac,af_22050,c_limit,w_1280,h_1280'
  },
  // Language keys follow ISO 639-1
  // https://www.w3schools.com/tags/ref_language_codes.asp
  languages: {
    cs: {
      enabled: true,
      english: 'Czech',
      "native": 'Čeština'
    },
    da: {
      enabled: true,
      english: 'Danish',
      "native": 'Dansk'
    },
    de: {
      enabled: true,
      english: 'German',
      "native": 'Deutsch'
    },
    en: {
      enabled: true,
      english: 'English',
      "native": 'English'
    },
    es: {
      enabled: true,
      english: 'Spanish',
      "native": 'Español'
    },
    fi: {
      enabled: true,
      english: 'Finnish',
      "native": 'Suomi'
    },
    fr: {
      enabled: true,
      english: 'French',
      "native": 'Français'
    },
    hr: {
      enabled: true,
      english: 'Croatian',
      "native": 'Hrvatski'
    },
    hu: {
      enabled: true,
      english: 'Hungarian',
      "native": 'Magyar'
    },
    it: {
      enabled: true,
      english: 'Italian',
      "native": 'Italiano'
    },
    ja: {
      enabled: false,
      english: 'Japanese',
      "native": '日本語'
    },
    lt: {
      enabled: true,
      english: 'Lithuanian',
      "native": 'Lietuvis'
    },
    lv: {
      enabled: true,
      english: 'Latvian',
      "native": 'Latvietis'
    },
    nb: {
      enabled: true,
      english: 'Norwegian',
      "native": 'Norsk bokmål'
    },
    nl: {
      enabled: false,
      english: 'Dutch',
      "native": 'Nederlands'
    },
    pl: {
      enabled: true,
      english: 'Polish',
      "native": 'Polski'
    },
    pt: {
      enabled: true,
      english: 'Portuguese',
      "native": 'Português'
    },
    sq: {
      enabled: true,
      english: 'Albanian',
      "native": 'Shqip'
    },
    ro: {
      enabled: true,
      english: 'Romanian',
      "native": 'Română'
    },
    ru: {
      enabled: true,
      english: 'Russian',
      "native": 'Русский'
    },
    sr: {
      enabled: true,
      english: 'Serbian',
      "native": 'Srpski' // Српски

    },
    sv: {
      enabled: true,
      english: 'Swedish',
      "native": 'Svenska'
    },
    tr: {
      enabled: true,
      english: 'Turkish',
      "native": 'Türkçe'
    }
  }
};