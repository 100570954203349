import _toConsumableArray from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import deepcopy from 'deepcopy';
import dotProp from 'dot-prop-immutable';
import { includes, isEmpty, keys, map } from 'ramda';
import { enabledLanguages } from '@/lib/languages-utils';
import merge from '@/lib/merge';
export var completedChapters = function completedChapters(chapters) {
  return chapters.filter(function (chapter) {
    return chapter.completed;
  });
};
export var completionState = function completionState(course) {
  if (!course || isEmpty(course.chapters)) return 0;
  return completedChapters(course.chapters).length / course.chapters.length;
};
export var haveCompletedCourse = function haveCompletedCourse(course) {
  var chapters = course === null || course === void 0 ? void 0 : course.chapters;
  var uncompletedChapters = chapters === null || chapters === void 0 ? void 0 : chapters.find(function (ch) {
    return !ch.completed;
  });
  return Boolean(uncompletedChapters);
};

var pickBlocksLanguages = function pickBlocksLanguages(languages, blocks) {
  var copy = deepcopy(blocks);
  copy.forEach(function (block, index) {
    keys(block.content).forEach(function (language) {
      if (includes(language, languages)) {
        return;
      }

      copy[index] = dotProp["delete"](block, "content.".concat(language));
    });
  });
  return copy;
};

export function addTranslation(course, sourceLanguage, targetLanguage) {
  var extra = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  var clone = _objectSpread({}, course);

  if (clone.state === 'archived') {
    return clone;
  }

  if (!clone.description_translations) {
    dotProp.set(clone, 'description_translations', {});
  }

  if (!clone.title_translations) {
    dotProp.set(clone, 'title_translations', {});
  }

  clone = dotProp.set(clone, "title_translations.".concat(targetLanguage), clone.title);
  clone = dotProp.set(clone, "description_translations.".concat(targetLanguage), clone.description);

  if (!clone.source_language) {
    clone = dotProp.set(clone, 'source_language', sourceLanguage);
    clone = dotProp.set(clone, 'chapters', map(function (chapter) {
      return dotProp.set(chapter, 'pending_blocks', map(function (block) {
        var _dotProp$set;

        return dotProp.set(block, 'content', (_dotProp$set = {}, _defineProperty(_dotProp$set, sourceLanguage, _objectSpread({
          _meta: {}
        }, block.content)), _defineProperty(_dotProp$set, targetLanguage, _objectSpread({
          _meta: {}
        }, block.content)), _dotProp$set));
      })(chapter.blocks));
    })(clone.chapters));
    clone = dotProp.set(clone, 'chapters', clone.chapters.map(function (chapter) {
      return dotProp.set(chapter, 'blocks', pickBlocksLanguages([sourceLanguage], chapter.pending_blocks));
    }));
    return merge(clone, extra);
  }

  clone = dotProp.set(clone, 'chapters', clone.chapters.map(function (chapter) {
    var notApprovedLanguages = keys(clone.translations_statuses).filter(function (language) {
      return clone.translations_statuses[language] !== 'approved';
    });
    var blocks = chapter.blocks.map(function (block) {
      var sourceContent = dotProp.get(block, "content.".concat(sourceLanguage));
      return dotProp.set(block, "content.".concat(targetLanguage), sourceContent);
    });
    var sourceAndTargetBlocks = pickBlocksLanguages([sourceLanguage, targetLanguage], blocks);
    var notApprovedBlocks = pickBlocksLanguages(notApprovedLanguages, chapter.pending_blocks);
    var pendingBlocks = merge(sourceAndTargetBlocks, notApprovedBlocks);
    return dotProp.set(chapter, 'pending_blocks', pendingBlocks);
  }));
  return merge(clone, extra);
}
export function getApprovedTranslations(course) {
  var sourceLanguage = course.source_language,
      translationsStatuses = course.translations_statuses;
  var approvedTranslations = keys(translationsStatuses).filter(function (language) {
    return translationsStatuses[language] === 'approved';
  });
  return sourceLanguage && course.state === 'approved' ? [sourceLanguage].concat(_toConsumableArray(approvedTranslations)) : approvedTranslations;
}
export function getAllTranslations(course) {
  var sourceLanguage = course.source_language,
      translationsStatuses = course.translations_statuses;
  return sourceLanguage ? [sourceLanguage].concat(_toConsumableArray(keys(translationsStatuses))) : [];
}
export var getBlocksCount = function getBlocksCount(course) {
  return course.chapters.reduce(function (accumulator, _ref) {
    var blocks = _ref.blocks;
    return accumulator + blocks.length;
  }, 0);
};
export var isSourceTranslation = function isSourceTranslation(course, language) {
  var sourceLanguage = course.source_language || language;
  return sourceLanguage === language;
};
export var isTargetTranslation = function isTargetTranslation(course, language) {
  return !isSourceTranslation(course, language);
};
export var countLanguageCanBeAdded = function countLanguageCanBeAdded(course) {
  var courseLanguageCount = getAllTranslations(course).length;
  var availableLanguagesCount = keys(enabledLanguages()).length;
  return availableLanguagesCount - courseLanguageCount;
};
export var canLanguageBeAdded = function canLanguageBeAdded(course) {
  return countLanguageCanBeAdded(course) >= 1;
};

var getTranslationStatus = function getTranslationStatus(course) {
  var language = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var state = course.state,
      translationsStatuses = course.translations_statuses;

  if (language && language in translationsStatuses) {
    var _translationsStatuses;

    return (_translationsStatuses = translationsStatuses[language]) !== null && _translationsStatuses !== void 0 ? _translationsStatuses : state;
  }

  return state;
};

export var isTranslationStatusApproved = function isTranslationStatusApproved(course) {
  var language = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var status = getTranslationStatus(course, language);
  return status === 'approved';
};
export var isLanguageStatusInProgress = function isLanguageStatusInProgress(course) {
  var language = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var status = getTranslationStatus(course, language);
  return status === 'in_progress';
};

var getLanguageChapterBlocksPath = function getLanguageChapterBlocksPath(course, chapter, language) {
  var sourceLanguage = course.source_language;
  var isApproved = isTranslationStatusApproved(course, language);

  if (sourceLanguage === language) {
    return 'blocks';
  }

  if (sourceLanguage && !isApproved) {
    return 'pending_blocks';
  }

  return 'blocks';
};

export var getLanguageChapterBlocks = function getLanguageChapterBlocks(course, chapter, language) {
  return chapter[getLanguageChapterBlocksPath(course, chapter, language)];
};