import { intlReducer as intl } from 'react-intl-redux';
import { combineReducers } from 'redux';
import app from './app';
import core from './core';
import error from './error';
import mobile from './mobile';
import player from './player';
import theme from './theme'; // Training

import training from './training';
import chapter from './training/chapter';

var reducers = function reducers() {
  return combineReducers({
    app: app,
    core: core,
    error: error,
    intl: intl,
    mobile: mobile,
    player: player,
    theme: theme,
    // Training
    chapter: chapter,
    training: training
  });
};

export default reducers;