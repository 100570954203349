import { enabledLanguages } from '@/lib/languages-utils';
var isBrowser = true;
export var getNavigatorLanguage = function getNavigatorLanguage(navigator) {
  return Object.keys(enabledLanguages()).find(function (language) {
    return navigator.language.includes(language);
  });
};
export var getLocale = function getLocale() {
  var fallbackLanguage = 'en';
  if (!isBrowser) return fallbackLanguage;
  return getNavigatorLanguage(window.navigator) || fallbackLanguage;
};