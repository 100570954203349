import { rgba } from '@/lib/color-utils';
var defaultMode = 'LIGHT';

var fontFamily = function fontFamily() {
  for (var _len = arguments.length, fonts = new Array(_len), _key = 0; _key < _len; _key++) {
    fonts[_key] = arguments[_key];
  }

  return "system-ui, -apple-system, \"Segoe UI\", ".concat(fonts.join(','), ", \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"");
};

export var build = function build() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$mode = _ref.mode,
      mode = _ref$mode === void 0 ? defaultMode : _ref$mode,
      primary = _ref.primary;

  var values = {};
  values.mode = mode;
  values['breakpoint.small'] = '320px';
  values['breakpoint.medium'] = '800px';
  values['breakpoint.semilarge'] = '1000px';
  values['breakpoint.large'] = '1200px'; // Names by [Name that Color]
  // (http://chir.ag/projects/name-that-color/)

  values['color.alto'] = '#e0e0e0';
  values['color.abbey'] = '#505757';
  values['color.dodgerBlue'] = '#0bb6ff';
  values['color.darkBlue'] = '#2883ff';
  values['color.cadetBlue'] = '#AEB8C5';
  values['color.lightGray'] = '#EFF2F6';
  values['color.atoll'] = '#0D666D';
  values['color.athensGray'] = '#f5f7f9';
  values['color.black'] = '#000000';
  values['color.lightBlack'] = '#212123';
  values['color.blueZodiac'] = '#0a1934';
  values['color.brightGray'] = '#39404a';
  values['color.portGore'] = '#202743';
  values['color.buttermilk'] = '#ffefb3';
  values['color.catskillWhite'] = '#eff3f7';
  values['color.emerald'] = '#55d891';
  values['color.geyser'] = '#d2d8e0';
  values['color.grayChateau'] = '#a1a5af';
  values['color.gullGray'] = '#96a0ba';
  values['color.heather'] = '#babfd2';
  values['color.jumbo'] = '#828286';
  values['color.mako'] = '#43474d';
  values['color.mariner'] = '#2068d0';
  values['color.midGray'] = '#5f5f64';
  values['color.mirage'] = '#191d26';
  values['color.mountainMeadow'] = '#1EB97D';
  values['color.mystic'] = '#e3e6eb';
  values['color.nevada'] = '#686f79';
  values['color.osloGray'] = '#929395';
  values['color.radicalRed'] = '#ff446e';
  values['color.raven'] = '#777d8b';
  values['color.silverChalice'] = '#a5a5a5';
  values['color.shark'] = '#28282d';
  values['color.shuttleGray'] = '#515c68';
  values['color.stormGray'] = '#787b85';
  values['color.supernova'] = '#fccf01';
  values['color.white'] = '#ffffff';
  values['color.zircon'] = '#f8fbff';
  values['color.frenchGray'] = '#bbbbbc';
  values['color.app'] = '#27c8a5';
  values['color.primary'] = mode === 'EDITOR' ? values['color.darkBlue'] : primary || values['color.app'];
  values['color.secondary'] = '#f0eff7';
  values['color.light'] = '#787b85';
  values['color.disabled'] = '#8da2af';
  values['color.danger'] = '#dc2c30';
  values['color.pink'] = '#ff6384';
  values['color.success'] = '#00b977';
  values['color.approved'] = '#1eb97d';
  values['default.border.color'] = '#c8d1db';
  values['default.border.radius'] = '15px';
  values['default.border.radius.large'] = '8px';
  values['default.border.radius.small'] = '3px';
  values['default.border.style'] = 'solid';
  values['default.border.width'] = '1px';
  values['default.border'] = "\n    ".concat(values['default.border.width'], "\n    ").concat(values['default.border.style'], "\n    ").concat(values['default.border.color']);
  values['default.box.shadow'] = '0 10px 20px 0 rgba(46, 51, 56, .1)';
  values['default.box.shadow.focus'] = "0 0 0 0.3rem ".concat(rgba(values['color.primary'], 0.4));
  values['default.box.shadow.desktop'] = values['default.box.shadow'];
  values['default.color'] = '#191a1c';
  values['default.color.hover'] = values['color.primary'];
  values['default.font.family.heading'] = fontFamily('Branding', 'Lato');
  values['default.font.family'] = fontFamily('"Open Sans"');
  values['default.font.size.xxsmall'] = '1rem';
  values['default.font.size.xsmall'] = '1.1rem';
  values['default.font.size.small'] = '1.2rem';
  values['default.font.size'] = '1.4rem';
  values['default.font.size.semiLarge'] = '1.5rem';
  values['default.font.size.large'] = '1.6rem';
  values['default.font.size.xlarge'] = '1.8rem';
  values['default.font.size.xxlarge'] = '2.1rem';
  values['default.font.size.xxxlarge'] = '2.4rem';
  values['default.opacity.disabled'] = 0.65;
  values['default.padding.desktop'] = '30px';
  values['default.padding'] = '20px';
  values['default.transition'] = '.3s ease-in-out';
  values['default.zIndex'] = 1020;
  values['zIndex.hidden'] = -1;
  values['zIndex.lvl.10'] = 10;
  values['zIndex.colorPicker'] = values['default.zIndex'];
  values['zIndex.dialog.overlay'] = values['default.zIndex'];
  values['zIndex.page.sidebar'] = values['default.zIndex'];
  values['zIndex.snackbar'] = values['default.zIndex'] + 1;
  values['zIndex.dropdown'] = 1070;
  values['zIndex.topLevel'] = 1071;
  values['zIndex.fullScreenWrapper'] = 1073;
  values['zIndex.fullScreenMode'] = 1076;
  values['zIndex.menuList'] = values['zIndex.dropdown'];
  values['zIndex.courseSearchOverlay'] = values['zIndex.dropdown'];
  values['zIndex.courseSearchSuggestionsContainer'] = values['zIndex.courseSearchOverlay'] + 1;
  values['zIndex.courseSearchInputOpen'] = values['zIndex.courseSearchSuggestionsContainer'] + 1;
  values['zIndex.courseSearchIcon'] = values['zIndex.courseSearchInputOpen'] + 1;
  values['zIndex.lightbox'] = values['zIndex.courseSearchIcon'] + 1;
  values['zIndex.courseCreateWizardOverlay'] = values['zIndex.courseSearchIcon'];
  values['zIndex.courseDescriptionOverlay'] = values['zIndex.courseSearchIcon'];
  values['zIndex.courseEditorSettingsModalOverlay'] = values['zIndex.courseSearchIcon'];
  values['zIndex.popup'] = values['zIndex.courseSearchIcon'] + 1;
  values['zIndex.listbox'] = values['zIndex.popup'] + 1;
  values['zIndex.photoEditor'] = values['zIndex.popup'] + 1;
  values['zIndex.fullScreenBackdrop'] = values['zIndex.courseSearchIcon'];
  values['zIndex.fullScreenInner'] = values['zIndex.fullScreenBackdrop'] + 1;
  values['zIndex.select.menu'] = values['zIndex.fullScreenInner'];
  values['zIndex.tooltip'] = values['zIndex.select.menu'];
  values['zIndex.courseCompleted'] = values['zIndex.fullScreenInner'] + 1;
  values['zIndex.externalResourceIframe'] = values['zIndex.courseCompleted'] + 1; // avatar

  values['avatar.border.radius'] = '5px';
  values['avatar.color'] = values['color.white'];
  values['avatar.font.family'] = values['default.font.family'];
  values['avatar.font.weight'] = 700; // badge

  values['badge.background.color'] = '#d4dae6'; // bar

  values['bar.height'] = '62px'; // breadcrumbs

  values['breadcrumbs.color'] = '#312d35';
  values['breadcrumbs.font.family'] = values['default.font.family'];
  values['breadcrumbs.font.size'] = '12px';
  values['breadcrumbs.font.size.desktop'] = '18px';
  values['breadcrumbs.height'] = '16px';
  values['breadcrumbs.height.desktop'] = '24px';
  values['breadcrumbs.icon.color'] = '#94979c';
  values['breadcrumbs.icon.size'] = '8px';
  values['breadcrumbs.padding'] = values['default.padding']; // body

  values['body.background.color'] = '#f3f4f6'; // button

  values['button.background.color.danger'] = values['color.danger'];
  values['button.background.color.primary'] = values['color.primary'];
  values['button.background.color.secondary'] = values['color.shuttleGray'];
  values['button.background.color.success'] = values['color.success'];
  values['button.background.color.pink'] = values['color.pink'];
  values['button.background.color.white'] = values['color.white'];
  values['button.background.color'] = values['color.mystic'];
  values['button.color.danger'] = values['color.white'];
  values['button.color.primary'] = values['color.white'];
  values['button.color.secondary'] = values['color.white'];
  values['button.color.success'] = values['color.white'];
  values['button.color.pink'] = values['color.white'];
  values['button.color.white'] = values['default.color'];
  values['button.color.brightGray'] = values['default.color.brightGray'];
  values['button.color'] = values['default.color'];
  values['card.background.color'] = values['color.white'];
  values['card.border.radius'] = values['default.box.radius'];
  values['card.box.shadow'] = values['default.box.shadow'];
  values['content.block.border.radius'] = values['default.border.radius'];
  values['content.block.font.size'] = '1.5rem';
  values['content.block.title.color'] = values['default.color'];
  values['content.block.title.font.size'] = '1.7rem';
  values['course.search.width'] = '250px';
  values['course.search.icon.fill'] = '#6d7176';
  values['dropdown.border'] = '0 solid rgba(0, 0, 0, .11)';
  values['dropdown.border.radius'] = '6px';
  values['dropdown.box.shadow'] = '0 10px 16px 0 rgba(34, 35, 36, .03), 0 20px 20px 0 rgba(135, 150, 180, .15), inset 0 0 0 1px rgba(0, 0, 0, 0.05);';
  values['dropdown.color'] = values['default.color'];
  values['dropdown.icon.color'] = '#7b7d82';
  values['dropdown.font.size'] = values['default.font.size.small'];
  values['error.icon.color'] = '#d6dde6';
  values['flag.background.color.international'] = '#eeeeee';
  values['fullScreen.background.color'] = values['body.background.color'];
  values['input.background.color'] = values['color.white'];
  values['input.border.color'] = '#ced2d6';
  values['input.border.color.danger'] = values['color.danger'];
  values['input.border.color.success'] = values['color.success'];
  values['input.border.style'] = values['default.border.style'];
  values['input.border.width'] = values['default.border.width'];
  values['input.border'] = "\n    ".concat(values['input.border.width'], "\n    ").concat(values['input.border.style'], "\n    ").concat(values['input.border.color']);
  values['input.border.color.focus'] = '#565b68';
  values['input.border.style.focus'] = values['default.border.style'];
  values['input.border.width.focus'] = values['default.border.width'];
  values['input.border.focus'] = "\n    ".concat(values['input.border.width.focus'], "\n    ").concat(values['input.border.style.focus'], "\n    ").concat(values['input.border.color.focus']);
  values['input.color'] = values['default.color'];
  values['input.append.background.color'] = '#e9ecef';
  values['input.placeholder.color'] = values['color.disabled'];
  values['layout.background.color'] = '#f6f7f9';
  values['link.color'] = values['color.primary'];
  values['link.color.disabled'] = values['color.disabled']; // logo

  values['logo.fill'] = values['default.color']; // menu

  values['menu.background.color'] = values['color.white'];
  values['menu.box.shadow'] = '5px 0 20px 0 rgba(142, 148, 162, .2)';
  values['menu.color'] = '#474f5d';
  values['menu.width'] = '216px';
  values['menu.width.desktop'] = '200px'; // page

  values['page.maxWidth'] = '1340px';
  values['page.body.maxWidth'] = values['page.maxWidth'];
  values['page.navbar.height'] = '60px';
  values['page.navbar.background.color'] = values['color.white'];
  values['page.navbar.maxWidth'] = values['page.maxWidth'];
  values['page.sidebar.background.color'] = values['color.white'];
  values['page.sidebar.width'] = '245px';
  values['page.sidebar.profile.width'] = '330px';
  values['page.sidebar.oneApp.profileBar'] = '250px';
  values['paper.background.color'] = values['color.white'];
  values['paper.border.color'] = 'rgba(0, 0, 0, 0.11)';
  values['paper.border.radius'] = '1.5rem';
  values['paper.border.width'] = '0.05rem';
  values['paper.border.style'] = 'solid';
  values['paper.box.shadow'] = '0 10px 30px 0 rgba(25,26,28,0.10)';
  values['paper.padding.desktop'] = values['default.padding.desktop'];
  values['paper.padding'] = values['default.padding'];
  values['search.box.border.radius'] = values['default.border.radius.large'];
  values['search.box.box.shadow'] = 'inset 2px 2px 4px 0 rgba(0, 0, 0, .1)';
  values['search.box.background.color'] = values['color.athensGray'];
  values['search.box.font.size'] = values['default.font.size.large'];
  values['search.box.font.weight'] = 'normal';
  values['search.box.color'] = values['color.silverChalice'];
  values['select.color'] = values['input.color'];
  values['select.border'] = values['input.border'];
  values['select.border.focus'] = values['input.border.focus'];
  values['select.placeholder.color'] = values['input.placeholder.color'];
  values['slider.color'] = '#96dbfa';
  values['snackbar.background.color'] = values['color.shuttleGray'];
  values['snackbar.border.radius'] = values['default.border.radius'];
  values['snackbar.box.shadow'] = values['default.box.shadow'];
  values['snackbar.width'] = '568px';
  values['spinner.border.color.primary'] = values['color.primary'];
  values['spinner.border.color'] = values['color.white'];
  values['spinner.size.semiMedium'] = 14;
  values['spinner.size'] = 18;
  values['spinner.size.semiLarge'] = 22;
  values['spinner.size.large'] = 30;
  values['text.color.danger'] = values['color.danger'];
  values['text.color.disabled'] = values['color.light'];
  values['text.color.light'] = values['color.light'];
  values['text.color.primary'] = values['color.primary'];
  values['text.color.secondary'] = values['color.secondary'];
  values['text.color.success'] = values['color.success'];
  values['text.color.white'] = values['color.white'];
  values['text.color'] = values['default.color'];
  values['text.font.size.xxsmall'] = values['default.font.size.xxsmall'];
  values['text.font.size.xsmall'] = values['default.font.size.xsmall'];
  values['text.font.size.small'] = values['default.font.size.small'];
  values['text.font.size'] = values['default.font.size'];
  values['text.font.size.semiLarge'] = values['default.font.size.semiLarge'];
  values['text.font.size.large'] = values['default.font.size.large'];
  values['text.font.size.xlarge'] = values['default.font.size.xlarge'];
  values['text.font.size.xxlarge'] = values['default.font.size.xxlarge'];
  values['text.font.size.xxxlarge'] = values['default.font.size.xxxlarge'];
  values['tooltip.arrow.size'] = '0.5rem';
  values['tooltip.background'] = '#222222'; // legacy

  values['color.gray.text'] = values['text.color.light'];
  values['color.green.text'] = values['text.color.secondary'];
  values['color.blue.text'] = '#2cc1ff';
  values['color.light.gray'] = '#e3e6f3';
  values['color.gray'] = '#5e7794';
  values['color.gray.blue'] = '#465158';
  values['color.light.blue'] = '#0bb6ff';
  values['color.blue'] = '#143451';
  values['color.black'] = '#000';
  values['color.salmon.red'] = '#df1955';
  values['color.green'] = '#2cd820';
  values['color.correct.green'] = '#1eb77d';
  values['color.yellow'] = '#f9bf10';
  values['color.menu.text.color'] = '#474f5d';
  values['color.menu.separator.color'] = '#d8d8d8';
  values['color.chapter.sidebar.title'] = '#5f6c79';
  values['color.image.uploader.cancel.button'] = '#656e77';
  values['button.background.gray.dark'] = values['color.shuttleGray'];
  values['button.background.geyser'] = values['color.geyser'];
  values['button.background.blue.light'] = '#2cc1ff';
  values['edit.bar.save.feature.button.color'] = values['color.shuttleGray'];
  values['horizontal.line.background'] = '#c8d1db'; // StyledChapterSidebarListEdit

  values['chapter.sidebar.width'] = '300px'; // NewBlockSelector

  values['selector.color.text'] = '#67686B';
  values['selector.color.line'] = '#CED1D7';
  return values;
};
var theme = build();
export default theme;