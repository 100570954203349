import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as actionsTheme from '@/redux/actions/theme';
import theme, { build } from '@/theme';
export var initialState = _objectSpread(_objectSpread({}, theme), {}, {
  colors: {
    main: '#27c8a5'
  }
});
export var reducerTheme = function reducerTheme() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionsTheme.THEME_CHANGE_MODE:
      return _objectSpread(_objectSpread({}, state), build({
        mode: action.payload.mode,
        primary: state.colors.main
      }));

    case actionsTheme.THEME_INIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        colors: _objectSpread({}, action.payload.colors)
      }, build({
        mode: action.payload.mode,
        primary: action.payload.colors.main
      }));

    default:
      return state;
  }
};
export default reducerTheme;