import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import * as types from '@/redux/actions/training/search';
export var initialState = {
  query: '',
  suggestions: null
};

var reducerTrainingSearch = function reducerTrainingSearch() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case types.TRAINING_SEARCH_FETCH_SUCCESS:
    case types.SEARCH_QUERY_UPDATE:
      return _objectSpread(_objectSpread({}, state), action.payload);

    case types.TRAINING_SEARCH_FETCH_STOP:
      return _objectSpread({}, initialState);

    default:
      return _objectSpread({}, state);
  }
};

export default reducerTrainingSearch;