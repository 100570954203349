import ResourcesUserPolicy from '@/lib/resources/oneapp/user-policy';
import { catchError } from '@/redux/actions/error';
export var CORE_USER_POLICY_FETCH_START = 'CORE_USER_POLICY_FETCH_START';
export var CORE_USER_POLICY_FETCH_ERROR = 'CORE_USER_POLICY_FETCH_ERROR';
export var CORE_USER_POLICY_FETCH_SUCCESS = 'CORE_USER_POLICY_FETCH_SUCCESS';

var fetchCoreUserPolicyError = function fetchCoreUserPolicyError() {
  return {
    type: CORE_USER_POLICY_FETCH_ERROR
  };
};

var fetchCoreUserPolicyStart = function fetchCoreUserPolicyStart() {
  return {
    type: CORE_USER_POLICY_FETCH_START
  };
};

var fetchCoreUserPolicySuccess = function fetchCoreUserPolicySuccess(policy) {
  return {
    type: CORE_USER_POLICY_FETCH_SUCCESS,
    payload: {
      policy: policy
    }
  };
};

export function fetchCoreUserPolicy() {
  return function (dispatch) {
    dispatch(fetchCoreUserPolicyStart());
    return ResourcesUserPolicy.get().then(function (_ref) {
      var policy = _ref.data.policy;
      dispatch(fetchCoreUserPolicySuccess(policy));
    })["catch"](function (response) {
      dispatch(fetchCoreUserPolicyError());
      dispatch(catchError(response));
    });
  };
}