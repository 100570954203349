import ResourcesUserCourse from '@/lib/resources/user-course';
import { catchError } from '@/redux/actions/error';
export var TRAINING_SEARCH_FETCH_ERROR = 'TRAINING_SEARCH_FETCH_ERROR';
export var TRAINING_SEARCH_FETCH_START = 'TRAINING_SEARCH_FETCH_START';
export var TRAINING_SEARCH_FETCH_STOP = 'TRAINING_SEARCH_FETCH_STOP';
export var TRAINING_SEARCH_FETCH_SUCCESS = 'TRAINING_SEARCH_FETCH_SUCCESS';
export var SEARCH_QUERY_UPDATE = 'SEARCH_QUERY_UPDATE';

var searchCourseError = function searchCourseError() {
  return {
    type: TRAINING_SEARCH_FETCH_ERROR
  };
};

var searchCourseStart = function searchCourseStart() {
  return {
    type: TRAINING_SEARCH_FETCH_START
  };
};

export var searchCourseStop = function searchCourseStop() {
  return {
    type: TRAINING_SEARCH_FETCH_STOP
  };
};

var searchCourseSuccess = function searchCourseSuccess(suggestions) {
  return {
    type: TRAINING_SEARCH_FETCH_SUCCESS,
    payload: {
      suggestions: suggestions
    }
  };
};

export var searchQueryUpdate = function searchQueryUpdate(query) {
  return {
    type: SEARCH_QUERY_UPDATE,
    payload: {
      query: query
    }
  };
};
export function searchCourse(query) {
  return function (dispatch) {
    dispatch(searchCourseStart());
    return ResourcesUserCourse.search(query).then(function (_ref) {
      var data = _ref.data;
      dispatch(searchCourseSuccess(data.results));
    })["catch"](function (response) {
      dispatch(catchError(response));
      dispatch(searchCourseError());
    });
  };
}