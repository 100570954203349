export function isTrainingCourseChapterBlock(value) {
  return 'completed' in value;
}
export function isTrainingCourse(value) {
  if (value === null) return false;

  if ('approved_translations' in value) {
    return true;
  }

  return false;
}
export function isTrainingListItemCourse(value) {
  return value && value.type === 'course';
}
export function isTrainingListItemPackage(value) {
  return value && value.type === 'course_package';
}
export function isTrainingListItemLearningPath(value) {
  return value && value.type === 'learning_path';
}
export function isTrainingCourseChapter(value) {
  return 'completed' in value;
}
export function isLearningPathTranslation(value) {
  return 'sourceLanguage' in value;
}