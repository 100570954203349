import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { getNewChapter } from '@/lib/chapter-helpers';
import ChapterResource from '@/lib/resources/chapter';
import { catchError } from '@/redux/actions/error';
export var CHAPTER_COMPLETE_ERROR = 'CHAPTER_COMPLETE_ERROR';
export var CHAPTER_COMPLETE_START = 'CHAPTER_COMPLETE_START';
export var CHAPTER_COMPLETE_SUCCESS = 'CHAPTER_COMPLETE_SUCCESS';
export var CHAPTER_CREATE_START = 'CHAPTER_CREATE_START';
export var CHAPTER_CREATE_SUCCESS = 'CHAPTER_CREATE_SUCCESS';
export var CHAPTER_CREATE_ERROR = 'CHAPTER_CREATE_ERROR';
export var CHAPTER_DELETE_ERROR = 'CHAPTER_DELETE_ERROR';
export var CHAPTER_DELETE_START = 'CHAPTER_DELETE_START';
export var CHAPTER_DELETE_SUCCESS = 'CHAPTER_DELETE_SUCCESS';
export var CHAPTER_COPY_ERROR = 'CHAPTER_COPY_ERROR';
export var CHAPTER_COPY_START = 'CHAPTER_COPY_START';
export var CHAPTER_COPY_SUCCESS = 'CHAPTER_COPY_SUCCESS';
export var CHAPTER_UPDATE_ERROR = 'CHAPTER_UPDATE_ERROR';
export var CHAPTER_UPDATE_START = 'CHAPTER_UPDATE_START';
export var CHAPTER_UPDATE_SUCCESS = 'CHAPTER_UPDATE_SUCCESS';
export var CHAPTER_SET_METADATA = 'CHAPTER_SET_METADATA';
export var CHAPTER_RESET_METADATA = 'CHAPTER_RESET_METADATA';
export var CHAPTER_SET_SPEED_QUIZ_METADATA = 'CHAPTER_SET_SPEED_QUIZ_METADATA';
export var CHAPTER_SET_SPEED_QUIZ_INITIAL_METADATA = 'CHAPTER_SET_SPEED_QUIZ_INITIAL_METADATA';
export var CHAPTER_BLOCK_COMPLETION_CREATE_ERROR = 'CHAPTER_BLOCK_COMPLETION_CREATE_ERROR';
export var CHAPTER_BLOCK_COMPLETION_CREATE_START = 'CHAPTER_BLOCK_COMPLETION_CREATE_START';
export var CHAPTER_BLOCK_COMPLETION_CREATE_SUCCESS = 'CHAPTER_BLOCK_COMPLETION_CREATE_SUCCESS';
export var chapterSetSpeedQuizInitialMetadataAction = function chapterSetSpeedQuizInitialMetadataAction(_ref) {
  var contentBlockId = _ref.contentBlockId,
      currentChapterCompletionMetadata = _ref.currentChapterCompletionMetadata;
  return {
    type: CHAPTER_SET_SPEED_QUIZ_INITIAL_METADATA,
    payload: {
      contentBlockId: contentBlockId,
      currentChapterCompletionMetadata: currentChapterCompletionMetadata
    }
  };
};
export var chapterSetSpeedQuizMetadataAction = function chapterSetSpeedQuizMetadataAction(_ref2) {
  var contentBlockId = _ref2.contentBlockId,
      score = _ref2.score;
  return {
    type: CHAPTER_SET_SPEED_QUIZ_METADATA,
    payload: {
      contentBlockId: contentBlockId,
      score: score
    }
  };
};

var chapterSetMetadata = function chapterSetMetadata(metadata) {
  return {
    type: CHAPTER_SET_METADATA,
    metadata: metadata
  };
};

export var setChapterMetadata = function setChapterMetadata(metadata) {
  return function (dispatch) {
    dispatch(chapterSetMetadata(metadata));
  };
};
export var resetChapterMetadata = function resetChapterMetadata() {
  return {
    type: CHAPTER_RESET_METADATA
  };
};
export var completeChapterStart = function completeChapterStart(chapter, course) {
  return {
    type: CHAPTER_COMPLETE_START,
    chapter: chapter,
    course: course
  };
};
export var completeChapterSuccess = function completeChapterSuccess(chapter, course) {
  return {
    type: CHAPTER_COMPLETE_SUCCESS,
    chapter: chapter,
    course: course
  };
};
export var completeChapterError = function completeChapterError(chapter, course) {
  return {
    type: CHAPTER_COMPLETE_ERROR,
    chapter: chapter,
    course: course
  };
};

var createChapterError = function createChapterError() {
  return {
    type: CHAPTER_CREATE_ERROR
  };
};

var createChapterStart = function createChapterStart() {
  return {
    type: CHAPTER_CREATE_START
  };
};

var createChapterSuccess = function createChapterSuccess(chapter, course) {
  return {
    type: CHAPTER_CREATE_SUCCESS,
    chapter: chapter,
    course: course
  };
};

export var deleteChapterError = function deleteChapterError(course, chapter) {
  return {
    type: CHAPTER_DELETE_ERROR,
    payload: {
      chapter: chapter,
      course: course
    }
  };
};
export var deleteChapterStart = function deleteChapterStart(course, chapter) {
  return {
    type: CHAPTER_DELETE_START,
    payload: {
      chapter: chapter,
      course: course
    }
  };
};
export var deleteChapterSuccess = function deleteChapterSuccess(course, chapter) {
  return {
    type: CHAPTER_DELETE_SUCCESS,
    payload: {
      chapter: chapter,
      course: course
    }
  };
};
export var copyChapterError = function copyChapterError() {
  return {
    type: CHAPTER_COPY_ERROR
  };
};
export var copyChapterStart = function copyChapterStart() {
  return {
    type: CHAPTER_COPY_START
  };
};
export var copyChapterSuccess = function copyChapterSuccess(chapter, course) {
  return {
    type: CHAPTER_COPY_SUCCESS,
    chapter: chapter,
    course: course
  };
};
export var updateChapterError = function updateChapterError() {
  return {
    type: CHAPTER_UPDATE_ERROR
  };
};
export var updateChapterStart = function updateChapterStart() {
  return {
    type: CHAPTER_UPDATE_START
  };
};
export var updateChapterSuccess = function updateChapterSuccess(chapter, course) {
  return {
    type: CHAPTER_UPDATE_SUCCESS,
    payload: {
      chapter: chapter,
      course: course
    }
  };
};
export var completeChapter = function completeChapter(prevChapter, course, chapterMetadata) {
  return function (dispatch) {
    var nextChapter = _objectSpread(_objectSpread({}, prevChapter), {}, {
      completed: true
    });

    dispatch(completeChapterStart(nextChapter, course));
    return ChapterResource.complete(nextChapter.slug, chapterMetadata).then(function () {
      dispatch(resetChapterMetadata());
      dispatch(completeChapterSuccess(nextChapter, course));
    })["catch"](function (response) {
      dispatch(catchError(response));
      dispatch(completeChapterError(prevChapter, course));
    });
  };
};
export var createChapter = function createChapter(data, course) {
  return function (dispatch) {
    dispatch(createChapterStart());
    var newChapter = getNewChapter(course, data);
    return ChapterResource.create(course.slug, newChapter).then(function (response) {
      var chapter = response.data.chapter;
      dispatch(createChapterSuccess(chapter, course));
      return response.data;
    })["catch"](function (response) {
      dispatch(createChapterError());
      dispatch(catchError(response));
      return null;
    });
  };
};
export var deleteChapter = function deleteChapter(chapter, course, onSuccess) {
  return function (dispatch) {
    dispatch(deleteChapterStart(course, chapter));
    return ChapterResource["delete"](course.slug, chapter.slug).then(function () {
      dispatch(deleteChapterSuccess(course, chapter));
      onSuccess();
    })["catch"](function (response) {
      dispatch(deleteChapterError(course, chapter));
      dispatch(catchError(response));
    });
  };
};
export var copyChapter = function copyChapter(sourceCourse, sourceChapter, targetCourse, chapterTitle) {
  return function (dispatch) {
    dispatch(copyChapterStart());
    return ChapterResource.copy(sourceCourse.id, sourceChapter.id, {
      targetCourseId: targetCourse.id,
      chapterTitle: chapterTitle
    }).then(function (response) {
      var targetChapter = response.data.chapter;
      dispatch(copyChapterSuccess(targetChapter, targetCourse));
      return response;
    })["catch"](function (response) {
      dispatch(copyChapterError());
      dispatch(catchError(response));
      return response;
    });
  };
};
export var updateChapter = function updateChapter(data, course) {
  return function (dispatch) {
    dispatch(updateChapterStart());
    return ChapterResource.update(course.slug, data).then(function (response) {
      var chapter = response.data.chapter;
      dispatch(updateChapterSuccess(chapter, course));
    })["catch"](function (error) {
      var _error$response$data$, _error$response, _error$response$data;

      dispatch(updateChapterError());

      var _ref3 = (_error$response$data$ = error === null || error === void 0 ? void 0 : (_error$response = error.response) === null || _error$response === void 0 ? void 0 : (_error$response$data = _error$response.data) === null || _error$response$data === void 0 ? void 0 : _error$response$data.error) !== null && _error$response$data$ !== void 0 ? _error$response$data$ : {},
          id = _ref3.id,
          message = _ref3.message;

      if (id === 'invalid-update-chapter') {
        return Promise.reject(new Error(message));
      }

      dispatch(catchError(error));
      return null;
    });
  };
};