export var BlockType;

(function (BlockType) {
  BlockType["Audio"] = "audio";
  BlockType["Event"] = "event";
  BlockType["ExternalResource"] = "external-resource";
  BlockType["Gallery"] = "gallery";
  BlockType["Activity"] = "learning-activity";
  BlockType["NumberedList"] = "numbered-list";
  BlockType["Quiz"] = "quiz";
  BlockType["SpeedQuiz"] = "speed-quiz";
  BlockType["Quote"] = "quote";
  BlockType["RolePlay"] = "role-play";
  BlockType["Storyboard"] = "storyboard";
  BlockType["Text"] = "text";
  BlockType["Video"] = "media";
  BlockType["Poster"] = "poster";
  BlockType["GameSwipeQuiz"] = "game-swipe-quiz";
})(BlockType || (BlockType = {}));

// Later can be expanded
export function isStyledBlock(block, language) {
  if (language) {
    var translatedContent = block.content[language];
    return Boolean(translatedContent && 'styles' in translatedContent);
  }

  return 'styles' in block.content;
}