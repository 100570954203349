import httpStatus from 'http-status';
import EventApiRender from '@/lib/event-api/render';
import ResourcesPreviewCourse from '@/lib/resources/preview-course';
import UserAgent from '@/lib/user-agent';
import { catchError } from '@/redux/actions/error';
export var PREVIEW_COURSE_BLOCK_COMPLETE = 'PREVIEW_COURSE_BLOCK_COMPLETE';
export var PREVIEW_COURSE_FETCH_ERROR = 'PREVIEW_COURSE_FETCH_ERROR';
export var PREVIEW_COURSE_FETCH_START = 'PREVIEW_COURSE_FETCH_START';
export var PREVIEW_COURSE_FETCH_SUCCESS = 'PREVIEW_COURSE_FETCH_SUCCESS';
export var fetchPreviewCourseStart = function fetchPreviewCourseStart() {
  return {
    type: PREVIEW_COURSE_FETCH_START
  };
};
export var fetchPreviewCourseError = function fetchPreviewCourseError() {
  return {
    type: PREVIEW_COURSE_FETCH_ERROR
  };
};
export var fetchPreviewCourseSuccess = function fetchPreviewCourseSuccess(course) {
  return {
    type: PREVIEW_COURSE_FETCH_SUCCESS,
    payload: {
      course: course
    }
  };
};
export var fetchPreviewCourse = function fetchPreviewCourse(slug) {
  return function (dispatch) {
    dispatch(fetchPreviewCourseStart());
    return ResourcesPreviewCourse.find(slug).then(function (_ref) {
      var course = _ref.data.course;
      dispatch(fetchPreviewCourseSuccess(course));
    })["catch"](function (error) {
      var propagateError = error.response.status !== httpStatus.NOT_FOUND;
      dispatch(fetchPreviewCourseError());
      dispatch(catchError(error, {}, {
        propagateError: propagateError
      }));
      throw error;
    })["finally"](function () {
      if (UserAgent.isMobileApp()) {
        EventApiRender.completed();
      }
    });
  };
};
export var completePreviewCourseBlock = function completePreviewCourseBlock(course, chapter, block, data, extraParams) {
  return function (dispatch) {
    dispatch({
      type: PREVIEW_COURSE_BLOCK_COMPLETE,
      payload: {
        course: course,
        chapter: chapter,
        block: block,
        data: data,
        extraParams: extraParams
      }
    });
    return Promise.resolve();
  };
};