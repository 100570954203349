import { combineReducers } from 'redux';
import reducerTrainingCategories from './categories';
import reducerTrainingCourses from './courses';
import reducerTrainingFavorites from './favorites';
import reducerTrainingPreview from './preview';
import reducerTrainingSearch from './search';
import { reducerTrainingSections } from './sections';
import reducerTrainingTags from './tags';
var reducerTraining = combineReducers({
  categories: reducerTrainingCategories,
  courses: reducerTrainingCourses,
  favorites: reducerTrainingFavorites,
  preview: reducerTrainingPreview,
  search: reducerTrainingSearch,
  sections: reducerTrainingSections,
  tags: reducerTrainingTags
});
export default reducerTraining;