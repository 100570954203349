import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { head, propEq } from 'ramda';
import ResourceUserCourse from '@/lib/resources/user-course';
import { catchError } from '@/redux/actions/error';
export var COURSES_FETCH_WITH_TAG_ERROR = 'COURSES_FETCH_WITH_TAG_ERROR';
export var COURSES_FETCH_WITH_TAG_START = 'COURSES_FETCH_WITH_TAG_START';
export var COURSES_FETCH_WITH_TAG_SUCCESS = 'COURSES_FETCH_WITH_TAG_SUCCESS';

var fetchCoursesWithTagError = function fetchCoursesWithTagError() {
  return {
    type: COURSES_FETCH_WITH_TAG_ERROR
  };
};

var fetchCoursesWithTagStart = function fetchCoursesWithTagStart() {
  return {
    type: COURSES_FETCH_WITH_TAG_START
  };
};

var fetchCoursesWithTagSuccess = function fetchCoursesWithTagSuccess(tag) {
  return {
    type: COURSES_FETCH_WITH_TAG_SUCCESS,
    payload: {
      tag: tag
    }
  };
};

export function fetchCoursesWithTag(tagId) {
  return function (dispatch) {
    dispatch(fetchCoursesWithTagStart());
    return ResourceUserCourse.withTag(tagId).then(function (_ref) {
      var _head;

      var data = _ref.data;
      var id = Number(tagId);
      var courses = data.courses;
      var tag = (_head = head(courses)) === null || _head === void 0 ? void 0 : _head.tags.find(propEq('id', id)); // @ts-ignore

      dispatch(fetchCoursesWithTagSuccess(_objectSpread({
        courses: courses
      }, tag)));
    })["catch"](function (response) {
      dispatch(catchError(response));
      dispatch(fetchCoursesWithTagError());
    });
  };
}