import _toConsumableArray from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import arrayShuffle from 'array-shuffle';
import { find, findIndex, prop, propEq, sortBy } from 'ramda';
export var findIndexById = function findIndexById(collection, id) {
  return findIndex(propEq('id', id))(collection);
};
export var findBySlug = function findBySlug(collection, slug) {
  return find(propEq('slug', slug))(collection);
};
export var shuffle = function shuffle(array) {
  if (array.length <= 1) return _toConsumableArray(array);
  var nextArray = arrayShuffle(array);

  if (nextArray.every(function (item, index) {
    return item === array[index];
  })) {
    return shuffle(nextArray);
  }

  return nextArray;
};
export var toObject = function toObject(collection) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'id';
  var startIdx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var preserveIndex = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  return collection.reduce(function (accumulator, currentValue, currentIndex) {
    var id = currentValue[key];
    var index = currentValue.index;
    var shouldKeepExistingIndex = index !== undefined && preserveIndex;

    if (id !== undefined) {
      accumulator[id] = currentValue;
      accumulator[id].index = shouldKeepExistingIndex ? index : startIdx + currentIndex;
    }

    return accumulator;
  }, {});
};
export var toObjectWithPreservedIndex = function toObjectWithPreservedIndex(collection) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'id';
  var startIdx = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var preserveIndex = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  return toObject(collection, key, startIdx, preserveIndex);
};
export var nextOrFirst = function nextOrFirst(collection, currentIndex) {
  if (currentIndex === collection.length - 1) {
    return collection[0];
  }

  return collection[currentIndex + 1];
};
export var prevOrLast = function prevOrLast(collection, currentIndex) {
  if (currentIndex === 0) {
    return collection[collection.length - 1];
  }

  return collection[currentIndex - 1];
};
export var sortByIndex = sortBy(prop('index'));
export var updateIf = function updateIf(collection, predicate, update) {
  return collection.map(function (item) {
    if (predicate(item)) {
      return update(item);
    }

    return item;
  });
};
export function move(fromIndex, toIndex, list) {
  var item = list[fromIndex];
  var length = list.length;
  var diff = fromIndex - toIndex;

  if (diff > 0) {
    return [].concat(_toConsumableArray(list.slice(0, toIndex)), [item], _toConsumableArray(list.slice(toIndex, fromIndex)), _toConsumableArray(list.slice(fromIndex + 1, length)));
  }

  if (diff < 0) {
    var targetIndex = toIndex + 1;
    return [].concat(_toConsumableArray(list.slice(0, fromIndex)), _toConsumableArray(list.slice(fromIndex + 1, targetIndex)), [item], _toConsumableArray(list.slice(targetIndex, length)));
  }

  return list;
}