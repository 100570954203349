import _taggedTemplateLiteral from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject;

import styled from 'styled-components';
import { theme } from 'styled-tools';
import { darken } from '@/lib/color-utils';
import { media } from '@/components/utils/style';
var StyledErrorPage = styled.div.withConfig({
  displayName: "StyledErrorPage",
  componentId: "ebinpe-0"
})(["display:flex;margin:auto;line-height:1.15;font-weight:600;text-align:center;text-transform:uppercase;background-color:#eff0f5;color:", ";flex-flow:column nowrap;justify-content:center;white-space:pre;min-height:100vh;h1{color:", ";font-size:5.7rem;width:196px;height:196px;margin:0 auto;line-height:196px;position:relative;> svg{position:absolute;top:0;left:0;z-index:0;}> *:not(svg){position:relative;z-index:1;}}h2{font-size:2.4rem;", "}p{text-transform:none;}pre{margin-right:auto;margin-left:auto;border:1px solid ", ";border-radius:0.5rem;padding:1.5rem;background-color:#ffffff;font-family:monospace;font-size:1.1rem;font-weight:400;text-align:left;text-transform:none;color:#444444;}"], theme('color.black'), theme('color.white'), media.desktop(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n      font-size: 2.6rem;\n    "]))), darken('#eff0f5'));
export default StyledErrorPage;