import dotProp from 'dot-prop-immutable';
import * as actions from '@/redux/actions/app';
export var initialState = {
  update: false
};
export var reducerApp = function reducerApp() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actions.APP_UPDATE_ERROR:
    case actions.APP_UPDATE_START:
      return dotProp.set(state, 'update', false);

    case actions.APP_UPDATE_SUCCESS:
      return dotProp.set(state, 'update', true);

    default:
      return state;
  }
};
export default reducerApp;