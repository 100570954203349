import ResourcesTrainingTrainingCourses from '@/lib/resources/course-feedback';
export var DISMISS_COURSE_COMPLETION = 'DISMISS_COURSE_COMPLETION';
export var SET_COURSE_FEEDBACK_RATING = 'SET_COURSE_FEEDBACK_RATING';
export var SET_COURSE_FEEDBACK_TEXT = 'SET_COURSE_FEEDBACK_TEXT';
export var SUBMIT_COURSE_FEEDBACK_ERROR = 'SUBMIT_COURSE_FEEDBACK_ERROR';
export var SUBMIT_COURSE_FEEDBACK_START = 'SUBMIT_COURSE_FEEDBACK_START';
export var SUBMIT_COURSE_FEEDBACK_SUCCESS = 'SUBMIT_COURSE_FEEDBACK_SUCCESS';
export var setCourseFeedbackRating = function setCourseFeedbackRating(rating) {
  return {
    type: SET_COURSE_FEEDBACK_RATING,
    rating: rating
  };
};
export var setCourseFeedbackText = function setCourseFeedbackText(text) {
  return {
    type: SET_COURSE_FEEDBACK_TEXT,
    text: text
  };
};

var submitCourseFeedbackError = function submitCourseFeedbackError(course) {
  return {
    type: SUBMIT_COURSE_FEEDBACK_ERROR,
    payload: {
      course: course,
      rating: null
    }
  };
};

var submitCourseFeedbackStart = function submitCourseFeedbackStart(course, rating) {
  return {
    type: SUBMIT_COURSE_FEEDBACK_START,
    payload: {
      course: course,
      rating: rating
    }
  };
};

export var submitCourseFeedbackSuccess = function submitCourseFeedbackSuccess(course, rating) {
  return {
    type: SUBMIT_COURSE_FEEDBACK_SUCCESS,
    payload: {
      course: course,
      rating: rating
    }
  };
};
export var dismissCourseCompletion = function dismissCourseCompletion() {
  return {
    type: DISMISS_COURSE_COMPLETION
  };
};
export function submitCourseFeedback(course, rating) {
  return function (dispatch) {
    dispatch(submitCourseFeedbackStart(course, rating));
    return ResourcesTrainingTrainingCourses.add(course.slug, rating.rating, rating.feedback).then(function (_ref) {
      var data = _ref.data;
      dispatch(submitCourseFeedbackSuccess(course, {
        feedback: data.rating.feedback,
        rating: data.rating.rating
      }));
    })["catch"](function () {
      dispatch(submitCourseFeedbackError(course));
    });
  };
}