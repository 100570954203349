import styled, { css } from 'styled-components';
import { ifProp, prop, switchProp, theme } from 'styled-tools';
import { themeColorPrimary } from '@/components/utils/style';
var UIIcon = styled.svg.attrs(function (props) {
  return {
    'aria-hidden': props['aria-hidden'] || 'true',
    role: props.role || 'img'
  };
}).withConfig({
  displayName: "UIIcon",
  componentId: "sc-1itafgn-0"
})(["display:inline-block;", " ", " ", " ", ""], ifProp('color', css(["fill:", ";stroke:", ";"], prop('color'), prop('color'))), ifProp('size', css(["height:", "px;width:", "px;"], prop('size'), prop('size'))), switchProp(prop('variant', 'default'), {
  "default": css(["fill:", ";"], theme('default.color')),
  approved: css(["fill:", ";"], theme('color.approved')),
  brightGray: css(["fill:#39404a;"]),
  currentColor: css(["fill:currentColor;"]),
  danger: css(["fill:", ";"], theme('color.danger')),
  darkBlue: css(["fill:", ";"], theme('color.darkBlue')),
  disabled: css(["fill:", ";opacity:0.65;"], theme('default.color')),
  atoll: css(["fill:", ";"], theme('color.atoll')),
  dodgerBlue: css(["fill:", ";"], theme('color.dodgerBlue')),
  emerald: css(["fill:#52c685;"]),
  frenchGray: css(["fill:", ";"], theme('color.frenchGray')),
  geyser: css(["fill:", ";"], theme('color.geyser')),
  grayChateau: css(["fill:", ";"], theme('color.grayChateau')),
  gullGray: css(["fill:", ";"], theme('color.gullGray')),
  heather: css(["fill:", ";"], theme('color.heather')),
  light: css(["fill:", ";"], theme('color.light')),
  mako: css(["fill:", ";"], theme('color.mako')),
  manatee: css(["fill:#94979c;"]),
  mariner: css(["fill:", ";"], theme('color.mariner')),
  nevada: css(["fill:", ";"], theme('color.nevada')),
  primary: css(["fill:", ";"], themeColorPrimary),
  radicalRed: css(["fill:", ";"], theme('color.radicalRed')),
  raven: css(["fill:", ";"], theme('color.raven')),
  shark: css(["fill:", ";"], theme('color.shark')),
  shuttleGray: css(["fill:#545b68;"]),
  silverChalice: css(["fill:", ";"], theme('color.silverChalice')),
  stormGray: css(["fill:", ";"], theme('color.stormGray')),
  success: css(["fill:", ";"], theme('color.success')),
  white: css(["fill:", ";"], theme('color.white'))
}), switchProp(prop('stroke'), {
  "default": css(["stroke:", ";"], theme('default.color')),
  brightGray: css(["stroke:#39404a;"]),
  currentColor: css(["stroke:currentColor;"]),
  danger: css(["stroke:", ";"], theme('color.danger')),
  dodgerBlue: css(["stroke:#0bb6ff;"]),
  emerald: css(["stroke:#52c685;"]),
  frenchGray: css(["stroke:", ";"], theme('color.frenchGray')),
  light: css(["stroke:", ";"], theme('color.light')),
  mako: css(["stroke:", ";"], theme('color.mako')),
  manatee: css(["stroke:#94979c;"]),
  nevada: css(["stroke:", ";"], theme('color.nevada')),
  primary: css(["fill:", ";"], themeColorPrimary),
  radicalRed: css(["stroke:", ";"], theme('color.radicalRed')),
  raven: css(["stroke:#44505d;"]),
  shark: css(["stroke:", ";"], theme('color.shark')),
  shuttleGray: css(["stroke #545B68;"]),
  silverChalice: css(["stroke ", ";"], theme('color.silverChalice')),
  stormGray: css(["stroke:", ";"], theme('color.stormGray')),
  success: css(["stroke ", ";"], theme('color.success')),
  white: css(["stroke ", ";"], theme('color.white')),
  atoll: css(["stroke ", ";"], theme('color.atoll'))
}));
export default UIIcon;