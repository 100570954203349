import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import dotProp from 'dot-prop-immutable';
import { findIndexById } from '@/lib/array-utils';
import * as actionsTrainingChapter from '@/redux/actions/training/chapter';
import * as actionsTrainingCourse from '@/redux/actions/training/course';
import * as actionsTrainingCourseFeedback from '@/redux/actions/training/course-feedback';
import * as actionsTrainingFavorites from '@/redux/actions/training/favorites';
export var initialState = {
  objects: {}
};

var chapterComplete = function chapterComplete(state, course, chapter) {
  var chapterIndex = findIndexById(course.chapters, chapter.id);
  return dotProp.set(state, "objects.".concat(course.slug, ".chapters.").concat(chapterIndex, ".completed"), chapter.completed);
};

var reducerTrainingCourses = function reducerTrainingCourses() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionsTrainingChapter.CHAPTER_COMPLETE_ERROR:
    case actionsTrainingChapter.CHAPTER_COMPLETE_START:
    case actionsTrainingChapter.CHAPTER_COMPLETE_SUCCESS:
      return chapterComplete(state, action.course, action.chapter);

    case actionsTrainingCourse.COURSE_FETCH_ERROR:
    case actionsTrainingCourse.COURSE_FETCH_START:
      return _objectSpread({}, state);

    case actionsTrainingCourse.COURSE_FETCH_SUCCESS:
      return dotProp.set(state, "objects.".concat(action.course.slug), action.course);

    case actionsTrainingCourseFeedback.SUBMIT_COURSE_FEEDBACK_ERROR:
    case actionsTrainingCourseFeedback.SUBMIT_COURSE_FEEDBACK_START:
    case actionsTrainingCourseFeedback.SUBMIT_COURSE_FEEDBACK_SUCCESS:
      return dotProp.set(state, "objects.".concat(action.payload.course.slug, ".rating"), action.payload.rating);

    case actionsTrainingFavorites.COURSE_FAVOURITE_UPDATE_ERROR:
    case actionsTrainingFavorites.COURSE_FAVOURITE_UPDATE_SUCCESS:
    case actionsTrainingFavorites.COURSE_FAVOURITE_UPDATE_START:
      if (action.payload.slug in state.objects) {
        return dotProp.set(state, "objects.".concat(action.payload.slug, ".favorite"), action.payload.favorite);
      }

      return state;

    default:
      return state;
  }
};

export default reducerTrainingCourses;