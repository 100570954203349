import { isReviewApp } from '@/lib/env-utils';
var appName = process.env.HEROKU_APP_NAME;
var basePath = process.env.BASE_PATH;

function checkEnvVariable(envValue) {
  var envName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'BASE_PATH';

  if (!envValue) {
    throw new Error("Environment variable ".concat(envName, " not set"));
  }

  return envValue;
}

var subdomainPattern = new RegExp("(https?)://(.+).".concat(checkEnvVariable(basePath)));
export var getOrigin = function getOrigin() {
  var _window$location = window.location,
      hostname = _window$location.hostname,
      port = _window$location.port,
      protocol = _window$location.protocol;
  return "".concat(protocol, "//").concat(hostname).concat(port ? ":".concat(port) : '');
};

function domainElements() {
  var origin = getOrigin();
  var data = origin.match(subdomainPattern);
  var parsedSubdomain = data ? data[2] : null;
  return {
    host: origin,
    subdomain: parsedSubdomain
  };
}

export var getIdentifier = function getIdentifier() {
  return window.location.hostname.split('.')[0];
};
export function hasIdentifier() {
  return Boolean(getIdentifier());
}
export var isAppUrl = function isAppUrl(url) {
  return url.includes(checkEnvVariable(basePath));
};
export function rootUrlFor(organization) {
  var protocol = window.location.protocol;

  if (isReviewApp()) {
    return "".concat(protocol, "//").concat(checkEnvVariable(appName, 'HEROKU_APP_NAME'), ".herokuapp.com");
  }

  return "".concat(protocol, "//").concat(organization, ".").concat(checkEnvVariable(basePath));
}
export function rootUrl() {
  var protocol = window.location.protocol;

  if (isReviewApp()) {
    return "".concat(protocol, "//").concat(checkEnvVariable(appName, 'HEROKU_APP_NAME'), ".herokuapp.com");
  }

  return "".concat(protocol, "//").concat(checkEnvVariable(basePath));
}