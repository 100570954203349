import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import dotProp from 'dot-prop-immutable';
import httpStatus from 'http-status';
import Logger from '@/lib/logger';
export var ERROR_CATCH = 'ERROR_CATCH';
export var ERROR_RESET = 'ERROR_RESET';
export function catchError(error) {
  var extras = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var extraOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var defaultOptions = {
    logoutOnUnauthorized: true,
    propagateError: true
  };

  var options = _objectSpread(_objectSpread({}, defaultOptions), extraOptions);

  var sentry = !error.response;
  return function (dispatch) {
    var status = dotProp.get(error, 'response.status', httpStatus.OK);

    if (options.propagateError) {
      Logger.error(error, extras, {
        sentry: sentry
      });
      dispatch({
        type: ERROR_CATCH,
        payload: error
      });
    }
  };
}
export var resetError = function resetError() {
  return {
    type: ERROR_RESET
  };
};