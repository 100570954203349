import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import dotProp from 'dot-prop-immutable';
import { forEach, propEq } from 'ramda';
import { isStyledBlock } from '@/types';
import { getApprovedTranslations } from '@/lib/course-helpers';
export var findBlocksByLanguage = function findBlocksByLanguage(chapter, language) {
  var blocksPath = 'pending_blocks';

  if (!dotProp.get(chapter, "pending_blocks.0.content.".concat(String(language)))) {
    blocksPath = 'blocks';
  }

  return dotProp.get(chapter, blocksPath).map(function (block) {
    if (blocksPath === 'blocks') return block;
    var rootBlock = chapter.blocks.find(propEq('id', block.id));
    var externalValidation = rootBlock === null || rootBlock === void 0 ? void 0 : rootBlock.external_validation;
    if (!externalValidation) return block;
    return dotProp.set(block, 'external_validation', externalValidation);
  });
};
/* eslint-disable no-underscore-dangle */

export function filterVisibleBlocks(blocks, language) {
  if (language) {
    return blocks.filter(function (block) {
      var _block$content, _block$content$langua, _block$content$langua2;

      return !((_block$content = block.content) !== null && _block$content !== void 0 && (_block$content$langua = _block$content[language]) !== null && _block$content$langua !== void 0 && (_block$content$langua2 = _block$content$langua._meta) !== null && _block$content$langua2 !== void 0 && _block$content$langua2.hidden);
    });
  }

  return blocks.filter(function (block) {
    var _block$content2, _block$content2$_meta;

    return !((_block$content2 = block.content) !== null && _block$content2 !== void 0 && (_block$content2$_meta = _block$content2._meta) !== null && _block$content2$_meta !== void 0 && _block$content2$_meta.hidden);
  });
}
/* eslint-enable no-underscore-dangle */

var createChapter = function createChapter(languages, course, chapter) {
  var index = course.chapters.length;
  var titleTranslations = {};

  if (languages) {
    forEach(function (language) {
      if (course.source_language === language || !chapter.title) {
        return;
      }

      titleTranslations[language] = chapter.title;
    })(languages);
  }

  return _objectSpread(_objectSpread({}, chapter), {}, {
    index: index,
    title_translations: titleTranslations
  });
};

export var getNewChapter = function getNewChapter(course, chapter) {
  return createChapter(getApprovedTranslations(course), course, chapter);
};
export var extractBlockStyles = function extractBlockStyles(block, language) {
  var _block$content$langua3;

  if (!isStyledBlock(block, language)) return null;
  var styles = language ? (_block$content$langua3 = block.content[language]) === null || _block$content$langua3 === void 0 ? void 0 : _block$content$langua3.styles : block.content.styles;
  if (styles) return styles;
  return null;
};