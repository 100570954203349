import { createSelector } from 'reselect';

var mobileSelector = function mobileSelector(state) {
  return state.mobile;
};

export var getMobile = createSelector(mobileSelector, function (mobile) {
  return mobile;
});
export var getMobileUrn = createSelector(getMobile, function (mobile) {
  return mobile && mobile.urn;
});