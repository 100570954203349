import ResourcesOneappTrainingSections from '@/lib/resources/oneapp/training/sections';
import { catchError } from '@/redux/actions/error';
export var TRAINING_SECTIONS_FETCH_ERROR = 'TRAINING_SECTIONS_FETCH_ERROR';
export var TRAINING_SECTIONS_FETCH_START = 'TRAINING_SECTIONS_FETCH_START';
export var TRAINING_SECTIONS_FETCH_SUCCESS = 'TRAINING_SECTIONS_FETCH_SUCCESS';

var fetchTrainingSectionsError = function fetchTrainingSectionsError() {
  return {
    type: TRAINING_SECTIONS_FETCH_ERROR
  };
};

var fetchTrainingSectionsStart = function fetchTrainingSectionsStart() {
  return {
    type: TRAINING_SECTIONS_FETCH_START
  };
};

var fetchTrainingSectionsSuccess = function fetchTrainingSectionsSuccess(payload) {
  return {
    type: TRAINING_SECTIONS_FETCH_SUCCESS,
    payload: payload
  };
};

export function fetchTrainingSections() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function (dispatch) {
    dispatch(fetchTrainingSectionsStart());
    return ResourcesOneappTrainingSections.get().then(function (_ref) {
      var data = _ref.data;
      dispatch(fetchTrainingSectionsSuccess(data));
    })["catch"](function (response) {
      dispatch(fetchTrainingSectionsError());
      dispatch(catchError(response));
    });
  };
}