import ResourceTrainingCourse from '@/lib/resources/user-course';
import { catchError } from '@/redux/actions/error';
export var TRAINING_FAVORITES_FETCH_ERROR = 'TRAINING_FAVORITES_FETCH_ERROR';
export var TRAINING_FAVORITES_FETCH_START = 'TRAINING_FAVORITES_FETCH_START';
export var TRAINING_FAVORITES_FETCH_SUCCESS = 'TRAINING_FAVORITES_FETCH_SUCCESS';
export var COURSE_FAVOURITE_UPDATE_ERROR = 'COURSE_FAVOURITE_UPDATE_ERROR';
export var COURSE_FAVOURITE_UPDATE_START = 'COURSE_FAVOURITE_UPDATE_START';
export var COURSE_FAVOURITE_UPDATE_SUCCESS = 'COURSE_FAVOURITE_UPDATE_SUCCESS';
export var TRAINING_FAVORITES_RESET = 'TRAINING_FAVORITES_RESET';
export var userFavoritesReset = function userFavoritesReset() {
  return {
    type: TRAINING_FAVORITES_RESET
  };
};

var fetchUserFavoritesError = function fetchUserFavoritesError() {
  return {
    type: TRAINING_FAVORITES_FETCH_ERROR
  };
};

var fetchUserFavoritesStart = function fetchUserFavoritesStart() {
  return {
    type: TRAINING_FAVORITES_FETCH_START
  };
};

var fetchUserFavoritesSuccess = function fetchUserFavoritesSuccess(_ref) {
  var courses = _ref.courses,
      learningPaths = _ref.learningPaths;
  return {
    type: TRAINING_FAVORITES_FETCH_SUCCESS,
    payload: {
      courses: courses,
      learningPaths: learningPaths
    }
  };
};

export function fetchUserFavorites() {
  return function (dispatch) {
    dispatch(fetchUserFavoritesStart());
    return ResourceTrainingCourse.favorites().then(function (_ref2) {
      var _ref2$data = _ref2.data,
          courses = _ref2$data.courses,
          learning_paths = _ref2$data.learning_paths;
      dispatch(fetchUserFavoritesSuccess({
        learningPaths: learning_paths,
        courses: courses
      }));
    })["catch"](function (response) {
      dispatch(fetchUserFavoritesError());
      dispatch(catchError(response));
    });
  };
}

var updateCourseFavoriteError = function updateCourseFavoriteError(payload) {
  return {
    type: COURSE_FAVOURITE_UPDATE_ERROR,
    payload: payload
  };
};

var updateCourseFavoriteStart = function updateCourseFavoriteStart(payload) {
  return {
    type: COURSE_FAVOURITE_UPDATE_START,
    payload: payload
  };
};

var updateCourseFavoriteSuccess = function updateCourseFavoriteSuccess(payload) {
  return {
    type: COURSE_FAVOURITE_UPDATE_SUCCESS,
    payload: payload
  };
};

export function updateCourseFavorite(args) {
  var slug = args.slug,
      favorite = args.favorite,
      onSuccess = args.onSuccess;
  return function (dispatch) {
    dispatch(updateCourseFavoriteStart({
      slug: slug,
      favorite: favorite
    }));
    return ResourceTrainingCourse.update(slug, {
      favorite: favorite
    }).then(function (response) {
      dispatch(updateCourseFavoriteSuccess({
        slug: slug,
        favorite: favorite
      }));
      if (onSuccess) onSuccess(response.data.course);
    })["catch"](function (response) {
      dispatch(updateCourseFavoriteError({
        slug: slug,
        favorite: !favorite
      }));
      dispatch(catchError(response));
    });
  };
}