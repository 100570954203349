import yn from 'yn';
import merge from '@/lib/merge';
import UserAgent from '@/lib/user-agent';
import defaultFlags from './default';
export default merge(defaultFlags, {
  userAgent: {
    audioVersionOneApp: !UserAgent.isMobileApp() || UserAgent.isMobileApp() && UserAgent.isAudioVersionOneApp(),
    desktop: !UserAgent.isMobileApp(),
    eventApiVersion: UserAgent.getEventApiVersion(),
    mobile: UserAgent.isMobileApp()
  },
  flags: {},
  flagsOneApp: {},
  features: {
    quizV2: yn(process.env.FEATURE_QUIZ_V2),
    todayScreen: yn(process.env.FEATURE_TODAY_SCREEN)
  }
});