import _toConsumableArray from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { reject } from 'ramda';
import * as actionsTrainingChapter from '@/redux/actions/training/chapter';
export var initialState = {
  metadata: null
};

function setChapterSpeedQuizMetadata(state, action) {
  var _metadata$speedQuizze;

  var metadata = state.metadata;
  var currentScores = metadata === null || metadata === void 0 ? void 0 : metadata.speedQuizzes.find(function (_ref) {
    var blockId = _ref.blockId;
    return blockId === action.payload.contentBlockId;
  });
  var speedQuizes = (_metadata$speedQuizze = metadata === null || metadata === void 0 ? void 0 : metadata.speedQuizzes) !== null && _metadata$speedQuizze !== void 0 ? _metadata$speedQuizze : [];

  if (currentScores) {
    return {
      metadata: _objectSpread(_objectSpread({}, metadata), {}, {
        speedQuizzes: speedQuizes.map(function (data) {
          if (data.blockId === action.payload.contentBlockId) {
            return _objectSpread(_objectSpread({}, data), {}, {
              scores: [].concat(_toConsumableArray(data.scores), [{
                value: action.payload.score,
                completedAt: new Date().toISOString()
              }])
            });
          }

          return data;
        })
      })
    };
  }

  return {
    metadata: _objectSpread(_objectSpread({}, metadata), {}, {
      speedQuizzes: [].concat(_toConsumableArray(speedQuizes), [{
        blockId: action.payload.contentBlockId,
        scores: [{
          value: action.payload.score,
          completedAt: new Date().toISOString()
        }]
      }])
    })
  };
}

function setChapterSpeedQuizInitialMetadata(state, action) {
  var _state$metadata, _state$metadata2;

  var currentBlockMetadata = (_state$metadata = state.metadata) === null || _state$metadata === void 0 ? void 0 : _state$metadata.speedQuizzes.find(function (data) {
    return data.blockId === action.payload.contentBlockId;
  });
  var speedQuizzes = ((_state$metadata2 = state.metadata) === null || _state$metadata2 === void 0 ? void 0 : _state$metadata2.speedQuizzes) || [];

  if (currentBlockMetadata) {
    return {
      metadata: _objectSpread(_objectSpread({}, state.metadata), {}, {
        speedQuizzes: speedQuizzes.map(function (data) {
          if (data.blockId === action.payload.contentBlockId) {
            return _objectSpread(_objectSpread({}, data), {}, {
              scores: [].concat(_toConsumableArray(data.scores), [action.payload.currentChapterCompletionMetadata])
            });
          }

          return data;
        })
      })
    };
  }

  return {
    metadata: _objectSpread(_objectSpread({}, state.metadata), {}, {
      speedQuizzes: [].concat(_toConsumableArray(speedQuizzes), [{
        blockId: action.payload.contentBlockId,
        scores: action.payload.currentChapterCompletionMetadata
      }])
    })
  };
}

function setChapterMetadata(state, action) {
  var newQuizMetadata = action.metadata;

  var rejectExistingEntry = function rejectExistingEntry(quizMetadata) {
    return quizMetadata.block_id === newQuizMetadata.block_id;
  };

  var metadata = state.metadata;
  var quizzesMetadata = [];

  if (metadata && Array.isArray(metadata.quizzes)) {
    quizzesMetadata = reject(rejectExistingEntry, metadata.quizzes);
  }

  return {
    metadata: _objectSpread(_objectSpread({}, metadata), {}, {
      quizzes: [newQuizMetadata].concat(_toConsumableArray(quizzesMetadata))
    })
  };
}

var reducerTrainingChapter = function reducerTrainingChapter() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionsTrainingChapter.CHAPTER_SET_SPEED_QUIZ_METADATA:
      return _objectSpread(_objectSpread({}, state), setChapterSpeedQuizMetadata(state, action));

    case actionsTrainingChapter.CHAPTER_SET_SPEED_QUIZ_INITIAL_METADATA:
      return _objectSpread(_objectSpread({}, state), setChapterSpeedQuizInitialMetadata(state, action));

    case actionsTrainingChapter.CHAPTER_SET_METADATA:
      return _objectSpread(_objectSpread({}, state), setChapterMetadata(state, action));

    case actionsTrainingChapter.CHAPTER_RESET_METADATA:
      return _objectSpread(_objectSpread({}, state), {}, {
        metadata: initialState.metadata
      });

    default:
      return state;
  }
};

export default reducerTrainingChapter;