/* eslint no-console: 0 */
var defaultOptions = {
  sentry: true
};

var isTest = function isTest() {
  return false;
};

var error = function error(exception) {
  var extras = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : defaultOptions;
  var tags = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  if (!isTest()) {
    console.error(exception, extras);
  }

  return exception;
};

var info = function info(message) {
  var extras = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : defaultOptions;

  if (!isTest()) {
    console.info(message, extras);
  }

  return message;
};

var report = function report() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultOptions;
};

var warning = function warning(message) {
  var extras = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : defaultOptions;

  if (!isTest()) {
    console.warn(message, extras);
  }

  return message;
};

export default {
  error: error,
  info: info,
  report: report,
  warning: warning
};