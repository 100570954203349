import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import dotProp from 'dot-prop-immutable';
import { findIndex, keys, propEq } from 'ramda';
import { toObject } from '@/lib/array-utils';
import * as actionsTrainingCourseFeedback from '@/redux/actions/training/course-feedback';
import * as actionsTrainingFavorites from '@/redux/actions/training/favorites';
import * as actionsTrainingSections from '@/redux/actions/training/sections';
export var initialState = {};

var updateItems = function updateItems(propertyName) {
  return function (state, slug, propertyValue) {
    return keys(state).reduce(function (accumulator, currentValue) {
      var items = accumulator[currentValue].items;
      var itemIndex = findIndex(propEq('slug', slug))(items);

      if (itemIndex === -1) {
        return accumulator;
      }

      return dotProp.set(accumulator, "".concat(String(currentValue), ".items.").concat(itemIndex, ".").concat(propertyName), propertyValue);
    }, state);
  };
};

var updateItemsFavorite = updateItems('favorite');
var updateItemsProgress = updateItems('progress');
export var reducerTrainingSections = function reducerTrainingSections() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case actionsTrainingCourseFeedback.SUBMIT_COURSE_FEEDBACK_START:
      return _objectSpread({}, updateItemsProgress(state, action.payload.course.slug, 1));

    case actionsTrainingSections.TRAINING_SECTIONS_FETCH_ERROR:
    case actionsTrainingSections.TRAINING_SECTIONS_FETCH_START:
      return state;

    case actionsTrainingSections.TRAINING_SECTIONS_FETCH_SUCCESS:
      return _objectSpread({}, toObject(action.payload.sections, 'identifier'));

    case actionsTrainingFavorites.COURSE_FAVOURITE_UPDATE_START:
    case actionsTrainingFavorites.COURSE_FAVOURITE_UPDATE_SUCCESS:
    case actionsTrainingFavorites.COURSE_FAVOURITE_UPDATE_ERROR:
      return _objectSpread({}, updateItemsFavorite(state, action.payload.slug, action.payload.favorite));

    default:
      return state;
  }
};
export default reducerTrainingSections;