import _defineProperty from "/vercel/path0/apps/legacy-open/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import Axios from 'axios';
import AuthClient from '@/lib/auth-client';
import { getIdentifier } from '../organization-utils';
var ResourcesTrainingCourse = {
  favorites: function favorites() {
    return AuthClient.get('/training/user/favorites');
  },
  find: function find(slug) {
    var identifier = getIdentifier() || '';
    return Axios("https://open-courses.s3-eu-west-1.amazonaws.com/".concat(identifier.replace('-open', ''), "-open/").concat(slug, ".json")).then(function (r) {
      return _objectSpread(_objectSpread({}, r), {}, {
        data: {
          course: _objectSpread(_objectSpread({}, r.data.course), {}, {
            locked_order: false
          })
        }
      });
    });
  },
  update: function update(slug, course) {
    return AuthClient.patch("/training/user/courses/".concat(slug), course);
  },
  search: function search(query) {
    return AuthClient.get('/training/user/courses/search', {
      params: {
        query: query
      }
    });
  },
  withTag: function withTag(tagId) {
    return AuthClient.get("/training/user/tags/".concat(tagId, "/courses"));
  }
};
export default ResourcesTrainingCourse;