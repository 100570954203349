import { createSelector } from 'reselect';

var themeSelector = function themeSelector(state) {
  return state.theme;
};

export var getTheme = createSelector(themeSelector, function (theme) {
  return theme;
});
export var getThemeColorPrimary = createSelector(themeSelector, function (theme) {
  return String(theme['color.primary']);
});
export var getThemeMode = createSelector(themeSelector, function (theme) {
  return theme.mode;
});